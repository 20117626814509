import loadable from "@loadable/component";
import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const CardRecommended = () => {
	const classes = useStyles("Home");

	const services = [
		{
			href: "https://lessons.com/music-lessons?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_guitar.png",
			primaryText: "Music Lessons",
		},
		{
			href: "https://tutors.com/?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_tutoring.png",
			primaryText: "Tutoring",
		},
		{
			href: "https://lessons.com/sports-training?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_sports.png",
			primaryText: "Sports Training",
		},
		{
			href: "https://lessons.com/performing-arts?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_dance.png",
			primaryText: "Dance & Acting Classes",
		},
		{
			href: "https://lessons.com/health-classes?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_fitness.png",
			primaryText: "Fitness & Martial Arts",
		},
		{
			href: "https://vety.com?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_pet.png",
			primaryText: "Pet Care",
		},
		{
			href: "https://thervo.com/spa?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_spa.png",
			primaryText: "Wellness & Spa",
		},
		{
			href: "https://thervo.com/therapists?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_counseling.png",
			primaryText: "Counseling & Therapy",
		},
		{
			href: "https://bettercare.com?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/icon_doctor.png",
			primaryText: "Health & Urgent Care",
		},
		{
			href: "https://talo.com?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/icon_web.png",
			primaryText: "Web design & development",
		},
		{
			href: "https://fash.com/weddings?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_wedding.png",
			primaryText: "Weddings & Events",
		},
		{
			href: "https://fash.com/photographers?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_photography.png",
			primaryText: "Photography",
		},
		{
			href: "https://thervo.com/tax-accountants?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_tax.png",
			primaryText: "Tax & Accounting",
		},
		{
			href: "https://lawful.com?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_law.png",
			primaryText: "Legal",
		},
		{
			href: "https://homeguide.com/home-remodeling?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_home.png",
			primaryText: "Home Remodeling",
		},
		{
			href: "https://homeguide.com/home-repair?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_repair.png",
			primaryText: "Home Repair",
		},
		{
			href: "https://homeguide.com/lawn-care?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_lawn.png",
			primaryText: "Lawn & Landscaping",
		},
		{
			href: "https://homeguide.com/cleaning?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/thumb_cleaning.png",
			primaryText: "Home Cleaning",
		},
		{
			href: "https://trustedcare.com?utm_source=recommended&utm_content=recommended&utm_campaign=recommended",
			imgSrc: "https://cdn.tutors.com/assets/images/misc/icon_baby.png",
			primaryText: "Child Care",
		},
	];

	return (
		<React.Fragment>
			<Box fontSize={24} mb={6} fontWeight={600}>
				Recommended for you
			</Box>

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Paper variant="outlined">
						<Box p={"12px 24px"}>
							<List component="nav">
								{services.map((service, index) => (
									<ListItem
										key={index}
										button
										component="a"
										href={service.href}
										target="_blank"
										disableGutters
										classes={{
											secondaryAction: classes.secondaryAction,
											container: classes.listItemContainer,
											root: classes.listItem,
										}}
									>
										<ListItemAvatar>
											<Avatar
												src={service.imgSrc}
												variant="rounded"
												className={classes.avatar}
												imgProps={{
													loading: "lazy",
													decoding: "async",
												}}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={service.primaryText}
											classes={{
												primary: classes.listPrimary,
												secondary: classes.listSecondary,
											}}
										/>
										<ListItemSecondaryAction className={classes.secondaryRoot}>
											<Button
												color="primary"
												style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
											>
												Search
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

CardRecommended.propTypes = {};
export default CardRecommended;

export const loader = (route) =>
	loadable(() => import("./CardRecommended.jsx"), {
		ssr: !!route.ssr,
	});
