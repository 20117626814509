export default (theme) => ({
	wrapper: {
		padding: "100px 60px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 16px",
		},
	},
	mainH2: {
		fontSize: 48,
		lineHeight: "56px",
		[theme.breakpoints.down("sm")]: {
			fontSize: 44,
			lineHeight: "52px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 28,
			lineHeight: "36px",
		},
		fontWeight: 700,
		textAlign: "left",
		marginBottom: "16px",
	},
	mainH3: {
		fontSize: 20,
		lineHeight: "28px",
		[theme.breakpoints.down("sm")]: {
			fontSize: 20,
			lineHeight: "28px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 18,
			lineHeight: "26px",
		},
		fontWeight: 700,
		textAlign: "left",
		marginBottom: "16px",
	},
	ul: {
		paddingLeft: 20,
		lineHeight: 1.7,
	},
	quoteHead: {
		fontSize: 42,
		lineHeight: "50px",
		fontWeight: 600,
		[theme.breakpoints.down("md")]: {
			fontSize: 34,
			lineHeight: "44px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: 26,
			lineHeight: "36px",
		},
	},
});
