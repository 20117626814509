import React from "react";
import { adjective as proAdjective } from "@language/provider";
import PropTypes from "prop-types";

import useStyles from "@hooks/useStyles";
import useConstants from "@hooks/useConstants";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const cities = [
	{ url: "/ga/atlanta/", name: "Atlanta" },
	{ url: "/tx/austin/", name: "Austin" },
	{ url: "/md/baltimore/", name: "Baltimore" },
	{ url: "/ma/boston/", name: "Boston" },
	{ url: "/il/chicago/", name: "Chicago" },
	{ url: "/tx/dallas/", name: "Dallas" },
	{ url: "/co/denver/", name: "Denver" },
	{ url: "/tx/houston/", name: "Houston" },
	{ url: "/nv/las-vegas/", name: "Las Vegas" },
	{ url: "/ca/los-angeles/", name: "Los Angeles" },
	{ url: "/fl/miami/", name: "Miami" },
	{ url: "/mn/minneapolis/", name: "Minneapolis" },
	{ url: "/tn/nashville/", name: "Nashville" },
	{ url: "/ny/new-york/", name: "New York" },
	{ url: "/pa/philadelphia/", name: "Philadelphia" },
	{ url: "/az/phoenix/", name: "Phoenix" },
	{ url: "/or/portland/", name: "Portland" },
	{ url: "/nc/raleigh/", name: "Raleigh" },
	{ url: "/ut/salt-lake-city/", name: "Salt Lake City" },
	{ url: "/ca/san-francisco/", name: "San Francisco" },
	{ url: "/wa/seattle/", name: "Seattle" },
	{ url: "/dc/washington/", name: "Washington DC" },
];

const HomeCities = () => {
	const classes = useStyles("HomeStates");

	const { citySlug } = useConstants("HomeBannerViewModel");

	return (
		<section className={classes.wrapper}>
			<Container maxWidth="lg" disableGutters>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					className={classes.head}
					mb={4}
				>
					<Typography component="h2" className={classes.mainH2}>
						Top cities for hiring a {proAdjective.singular}
					</Typography>
				</Box>

				<Box maxWidth={900}>
					{cities.map((city, index) => (
						<Button
							key={index}
							href={citySlug ? city.url + citySlug : city.url}
							variant="contained"
							className={classes.cityButton}
							disableElevation
						>
							{city.name}
						</Button>
					))}
				</Box>
			</Container>
		</section>
	);
};

HomeCities.propTypes = {
	states: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			abbreviation: PropTypes.string.isRequired,
		})
	),
};

export default HomeCities;
