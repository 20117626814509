import required from "@libs/required";
import { observable, action } from "mobx";
class ReviewsMetricsStore {
	@observable reviews_count = 0;
	@observable reviews_avg = 0;
	@observable reviews_five_star_count = 0;
	@observable reviews_four_star_count = 0;
	@observable reviews_three_star_count = 0;
	@observable reviews_two_star_count = 0;
	@observable reviews_one_star_count = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	getPercentStarReview(starCount, reviewCount) {
		if (reviewCount > 0) {
			return parseInt((starCount / reviewCount) * 100).toFixed(0);
		}
		return 0
	}

	get percentFiveStarReviews() {
		return this.getPercentStarReview(this.reviews_five_star_count, this.reviews_count)
	}

	get percentFourStarReviews() {
		return this.getPercentStarReview(this.reviews_four_star_count, this.reviews_count)
	}

	get percentThreeStarReviews() {
		return this.getPercentStarReview(this.reviews_three_star_count, this.reviews_count)
	}

	get percentTwoStarReviews() {
		return this.getPercentStarReview(this.reviews_two_star_count, this.reviews_count)
	}

	get percentOneStarReviews() {
		return this.getPercentStarReview(this.reviews_one_star_count, this.reviews_count)
	}

	@action
	init({
		reviews_count = required`reviews_count`,
		reviews_avg = required`reviews_avg`,
		reviews_five_star_count = required`reviews_five_star_count`,
		reviews_four_star_count = required`reviews_four_star_count`,
		reviews_three_star_count = required`reviews_three_star_count`,
		reviews_two_star_count = required`reviews_two_star_count`,
		reviews_one_star_count = required`reviews_one_star_count`,
	}) {
		this.reviews_count = reviews_count;
		this.reviews_avg = reviews_avg;
		this.reviews_five_star_count = reviews_five_star_count;
		this.reviews_four_star_count = reviews_four_star_count;
		this.reviews_three_star_count = reviews_three_star_count;
		this.reviews_two_star_count = reviews_two_star_count;
		this.reviews_one_star_count = reviews_one_star_count;
	}

	@action
	reset() {
		this.reviews_count = 0;
		this.reviews_avg = 0;
		this.reviews_five_star_count = 0;
		this.reviews_four_star_count = 0;
		this.reviews_three_star_count = 0;
		this.reviews_two_star_count = 0;
		this.reviews_one_star_count = 0;
	}
}

export default ReviewsMetricsStore;
