import React from "react";
import { observer } from "mobx-react";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

// MUI Icons

import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const { BRAND_NAME, LINK_HELP } = process.env;

const CardHelp = observer(() => {
	const classes = useStyles("Home");

	return (
		<>
			<div className={classes.homePaperBox}>
				<Paper variant="outlined" className={classes.homePaper}>
					<div className={classes.homeCard}>
						<div>
							<div className={classes.homeCardTitleBox}>
								<div className={classes.homeCardHeader}>{`Help & support`}</div>
							</div>
							<div className={classes.homeCardSubHeader}>
								{`We're here to assist you 7 days a week, rain or shine.`}
							</div>
							<Box mt={4}>
								<List component="nav" aria-label="profile tasks">
									<ListItem
										component="a"
										button
										href={LINK_HELP}
										target="_blank"
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<ContactSupportOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Getting starting" />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												href={LINK_HELP}
												target="_blank"
												color={"primary"}
											>
												<ArrowForwardIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>

									<ListItem
										component="a"
										button
										href={LINK_HELP}
										target="_blank"
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<ContactSupportOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="View help desk" />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												href={LINK_HELP}
												target="_blank"
												color={"primary"}
											>
												<ArrowForwardIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>

									<ListItem
										component="a"
										button
										href={LINK_HELP}
										target="_blank"
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<ContactSupportOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Get my booking links" />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												href={LINK_HELP}
												target="_blank"
												color={"primary"}
											>
												<ArrowForwardIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>

									<ListItem
										component="a"
										button
										href={LINK_HELP}
										target="_blank"
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<ContactSupportOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="Contact us" />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												href={LINK_HELP}
												target="_blank"
												color={"primary"}
											>
												<ArrowForwardIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</Box>
						</div>
					</div>
				</Paper>
			</div>
		</>
	);
});

export default CardHelp;
