export default (theme) => ({
	wrapper: {
		background: "#fafafa",
		paddingTop: 40,
		paddingBottom: 100,
		paddingLeft: 60,
		paddingRight: 60,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	head: {
		marginTop: 24,
		marginBottom: 32,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	seeAll: {
		display: "flex",
		alignItems: "center",
	},
	mainH2: {
		fontSize: 36,
		lineHeight: 1.6,
		[theme.breakpoints.down("sm")]: {
			fontSize: 26,
			lineHeight: "26px",
		},
		fontWeight: 600,
		margin: 0,
	},
	gridWrap: {
		marginBottom: 64,
	},
	subTitleBox: {
		lineHeight: 1.5,
		maxWidth: 700,
		marginBottom: 32,
		color: theme.palette.grey[700],
	},
});
