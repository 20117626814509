export default (theme) => ({
	wrapper: {
		height: "100vh",
		minHeight: "100vh",
		//minHeight: 540,
		maxHeight: 950,
		background: "#FFF",
		//background: "rgb(211, 241, 255)",
		[theme.breakpoints.down("xs")]: {
			height: "auto",
			display: "flex",
			alignItems: "center",
			//background: "#FFF",
		},
		position: "relative",
		zIndex: 2,
		width: "100%",
		borderBottom: "1px solid #e8e8e8",
	},
	heroBox: {
		[theme.breakpoints.up("sm")]: {
			"position": "absolute",
			"-webkit-transform": "translateY(-50%)",
			"transform": "translateY(-50%)",
		},
		[theme.breakpoints.down("xs")]: {
			position: "relative",
		},
		paddingLeft: 8,
		paddingRight: 8,
		width: "100%",
	},
	heroContainer: {
		maxWidth: 780,
	},
	mainH1: {
		fontSize: 66,
		lineHeight: 1,
		[theme.breakpoints.down("sm")]: {
			fontSize: 56,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 32,
			lineHeight: 1.2,
		},
		fontWeight: 600,
		textAlign: "left",
		marginTop: 0,
		marginBottom: 30,
	},
	flexCenter: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
		},
	},
});
