import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import useStyles from "@hooks/useStyles";

import Background from "@components/Dashboard/Client/Shared/Background";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import Popper from "@material-ui/core/Popper";

import GetAppIcon from "@material-ui/icons/GetApp";
import { Alert, AlertTitle } from "@material-ui/lab";

import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

import { adjective as proAdjective } from "@language/provider";
import CardUpcoming from "./CardUpcoming";
import CardBookAgain from "./CardBookAgain";
import CardRecommended from "./CardRecommended";

const Home = () => {
	const classes = useStyles("Home");

	const [openMenu, setOpenMenu] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	const hasBookings = true;

	return (
		<React.Fragment>
			<Background>
				<Box
					component="main"
					minHeight={"calc(100vh - 144px)"}
					pt={4}
					px={{ xs: 2, sm: 6 }}
					pb={20}
				>
					<Container maxWidth={"md"}>
						<Box
							mt={8}
							mb={8}
							display={"flex"}
							alignItems={{ xs: "flex-start", sm: "center" }}
							justifyContent={"space-between"}
							flexDirection={{ xs: "column", sm: "row" }}
						>
							<Box display={"flex"} flexDirection={"column"}>
								<Box display={"flex"} alignItems={"center"}>
									<Box fontWeight={600} fontSize={{ xs: 28, sm: 32 }} mr={4}>
										{`Welcome, Tom!`}
									</Box>
								</Box>
								{/* <Box mt={1}>
									{`Nice to see you again!`}
									
								</Box> */}
							</Box>
						</Box>
						<div className={classes.buttonRow}>
							<div>
								<div className={classes.buttonOverflow}>
									<div className={classes.touchWrap}>
										<Button
											size={"large"}
											href="/client/payments"
											target="_blank"
											color={"primary"}
											variant={"contained"}
											disableElevation
											className={classes.questionButton}
										>
											{`Go to payments`}
										</Button>
										<Button
											size={"large"}
											href="/client/bookings"
											target="_blank"
											variant={"contained"}
											disableElevation
											className={`${classes.questionButton} ${classes.homeButton}`}
										>
											{`My bookings`}
										</Button>
										<Button
											size={"large"}
											href="/client/team"
											target="_blank"
											variant={"contained"}
											disableElevation
											className={`${classes.questionButton} ${classes.homeButton}`}
										>
											{`My ${proAdjective.plural}`}
										</Button>
										<Button
											size={"large"}
											href="/client/members"
											target="_blank"
											variant={"contained"}
											disableElevation
											className={`${classes.questionButton} ${classes.homeButton}`}
										>
											{`My members`}
										</Button>
									</div>
								</div>
							</div>
						</div>
						<Popper
							open={openMenu}
							anchorEl={anchorRef.current}
							role={undefined}
							transition
							disablePortal
							placement={"bottom-end"}
							style={{ zIndex: 1500 }}
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === "bottom-end" ? "bottom-end" : "bottom-end",
									}}
								>
									<Paper elevation={6} style={{ width: 250 }}>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList
												id="menu-list-grow"
												onKeyDown={handleListKeyDown}
											>
												<MenuItem
													component={"a"}
													href="/xxx"
													onClick={handleClose}
												>
													<ListItemIcon className={classes.adminListItemIcon}>
														<GetAppIcon />
													</ListItemIcon>

													<ListItemText
														classes={{
															primary: classes.listPrimary,
															secondary: classes.listSecondary,
														}}
														primary={"CSV"}
													/>
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>

						<Box mb={8}>
							<Alert
								severity="warning"
								action={
									<Button
										color="secondary"
										variant="contained"
										disableElevation
									>
										Pay now
									</Button>
								}
							>
								There are <strong>2 unpaid invoices</strong> with a total of{" "}
								<strong>$400.00</strong>
							</Alert>
						</Box>

						<Grid container spacing={10}>
							{hasBookings && (
								<Grid item xs={12}>
									<CardUpcoming />
								</Grid>
							)}
							<Grid item xs={12}>
								<Grid container spacing={7}>
									{hasBookings && (
										<Grid item xs={12} sm={12} md={6}>
											<CardBookAgain />
										</Grid>
									)}
									<Grid item xs={12} sm={12} md={hasBookings ? 6 : 12}>
										<CardRecommended />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Background>
		</React.Fragment>
	);
};

export default Home;
export const loader = (route) =>
	loadable(() => import("./Home.component"), { ssr: !!route.ssr });
