import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import { styles, stores } from "./.config";
import { compose } from "recompose";

import withStyles from "@framework/src/libs/withStyles";

import {
	Container,
	Box,
	LinearProgress,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";

const { BRAND_NAME } = process.env;

import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";
import OnboardingItems from "./OnboardingItems";

function LinearProgressWithLabel(props) {
	const classes = useStyles("Onboarding");
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={3}>
				<LinearProgress
					variant="determinate"
					{...props}
					classes={{
						root: classes.lproot,
						colorPrimary: classes.lpcolorPrimary,
						bar: classes.lpbar,
					}}
				/>
			</Box>
			<Box minWidth={35}>
				<Box color={"#0e6be6"} fontWeight={600} fontSize={14}>{`${Math.round(
					props.value
				)}%`}</Box>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

const Onboarding = compose(withStyles(styles))(({ fullView }) => {
	const classes = useStyles("Onboarding");

	const [progress, setProgress] = React.useState(69);

	// primary

	const [showList2, setShowList2] = useState(true); // State to track list visibility

	const handleToggleList2 = () => {
		setShowList2(!showList2); // Toggle the visibility
	};

	// advanced

	const [showList, setShowList] = useState(false); // State to track list visibility

	const handleToggleList = () => {
		setShowList(!showList); // Toggle the visibility
	};

	const steps2 = [
		{
			icon: <PersonAddOutlinedIcon />,
			primary: "Import your customer list",
			secondary: `Import your customer information to streamline your booking process.`,
			hasClose: false,
			isCompleted: false,
		},

		{
			icon: <DateRangeOutlinedIcon />,
			primary: "Review booking settings",
			secondary: `Confirm where, when, and how you accept bookings.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <ListAltOutlinedIcon />,
			primary: "Create a test appointment",
			secondary: `Scheduling a test appointment is useful as it's the same process for booking a  ${clientAdjective.singular}'s appointment directly, instead of directing them to your booking site.`,
			link: "/pros/appointments",
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <GradeOutlinedIcon />,
			primary: "Ask existing customers for reviews",
			secondary: `Feedback builds trust and credibility, helping you attract new customers.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <PhotoOutlinedIcon />,
			primary: "Add photos and videos to your profile",
			secondary: `Boost your profile with engaging photos and videos to easily attract more clients.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <WebOutlinedIcon />,
			primary: "Review and edit your free website",
			secondary: `Let your customers book online 24/7 with a 100% free, easy-to-use website that reflects your style.`,
			hasClose: false,
			isCompleted: false,
		},
	];

	return (
		<>
			<Box
				display={"flex"}
				flexDirection={"column"}
				borderLeft={"1px #d9d9d9 solid"}
				boxShadow={"0 4px 8px rgba(0,0,0,.1), 0 2px 16px rgba(0,0,0,.1)"}
				width={{ xs: "100%", sm: 560 }}
				height={"100%"}
				bgcolor={"#FFFFFF"}
			>
				<Box
					minHeight={72}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					p={"12px 8px"}
					//position={"fixed"}
					right={0}
					top={0}
				>
					<Box position={"absolute"} top={12} left={12}>
						<IconButton
							aria-label="close"
							color="secondary"
							//onClick={() => {store.onClose(true);}}
						>
							<CloseOutlinedIcon />
						</IconButton>
					</Box>

					<Box fontSize={22} fontWeight={600} ml={3}>
						Setup guide
					</Box>
				</Box>
				<Box
					component={"div"}
					maxHeight={"100%"}
					style={{ overflowY: "auto" }}
					position={"relative"}
					flex={"1 0 0px"}
					className={classes.scrollbar}
					//bgcolor={"#f7f7f7"}
					borderTop={"1px solid rgba(0, 0, 0, 0.12)"}
					borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
				>
					<Container>
						<Box p={{ xs: 4, sm: 6 }}>
							<OnboardingItems />
						</Box>
					</Container>
				</Box>
			</Box>
		</>
	);
});

Onboarding.propTypes = {
	fullView: PropTypes.bool,
};

Onboarding.defaultProps = {
	fullView: false,
};
export default Onboarding;
