import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import loadable from "@loadable/component";

// Hooks
import useStaticContext from "@hooks/useStaticContext";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import EmptyMessage from "@custom/EmptyMessage";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";

const { BRAND_NAME, SITE_NAME, ENV } = process.env;

const CardSales = observer(
	({
		title,
		subTitle,
		number,
		numberText,
		avatar,
		primary,
		secondary,
		empty,
	}) => {
		const classes = useStyles("Home");

		return (
			<React.Fragment>
				<div className={classes.homePaperBox}>
					<Paper variant="outlined" className={classes.homePaper}>
						<div className={classes.homeCard}>
							<div>
								<div className={classes.homeCardTitleBox}>
									<div className={classes.homeCardHeader}>Sales</div>
									{empty ? (
										<>
											<Tooltip
												title="You need at least one review to start getting new customers."
												arrow
												TransitionComponent={Zoom}
												placement={"top"}
											>
												<Chip
													color="primary"
													size="small"
													label="Free to use"
													style={{
														color: "#0d8d33",
														backgroundColor: "#e2f9e9",
													}}
												/>
											</Tooltip>
										</>
									) : (
										<div className={classes.metricsTitle}>Last 30 days</div>
									)}
								</div>
								{empty ? (
									<>
										<Box mb={8} mt={12} display={"flex"}>
											<EmptyMessage
												header={"Accept payments online and in-person"}
												body={`Easily take payments for your services, classes, and memberships—online, in-person, and remotely. Receive daily payouts directly to your bank account.`}
												icon={<LocalAtmOutlinedIcon style={{ fontSize: 40 }} />}
												//variant={false}
												buttonName={"Set up payments"}
												buttonLink={"/pros/services"}
												thin
												//buttonSize="large"
											/>
										</Box>
									</>
								) : (
									<List>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemText
												primary="Sales"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"success.main"}
															fontWeight={600}
														>
															<ArrowDropUpIcon />
															44.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>$4,300.21</div>
										</ListItem>

										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemText
												primary="Collected"
												secondary={"75%"}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>$3,300.21</div>
										</ListItem>

										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemText
												primary="Past due"
												secondary={"25%"}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>$1,300.21</div>
										</ListItem>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemText
												primary="Jobs done"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"error.main"}
															fontWeight={600}
														>
															<ArrowDropDownIcon />
															74.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>18</div>
										</ListItem>
									</List>
								)}
							</div>

							{!empty && (
								<div className={classes.homeCardCTA}>
									<Link
										href="/pros/reviews"
										color={"primary"}
										className={classes.homeLink}
									>
										view all
									</Link>
									<Box height={20}>
										<Divider light orientation="vertical" />
									</Box>
									<Link color={"primary"} className={classes.homeLink}>
										take payment
									</Link>
									<Box height={20}>
										<Divider light orientation="vertical" />
									</Box>
									<Link color={"primary"} className={classes.homeLink}>
										see payouts
									</Link>
								</div>
							)}
						</div>
					</Paper>
				</div>
			</React.Fragment>
		);
	}
);

export default CardSales;
