import React from "react";
import { adjective as proAdjective } from "@language/provider";
import PropTypes from "prop-types";

import useStyles from "@hooks/useStyles";
import useStaticContext from "@hooks/useStaticContext";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const statesStatic = [
	{ code: "ak", name: "Alaska" },
	{ code: "al", name: "Alabama" },
	{ code: "ar", name: "Arkansas" },
	{ code: "az", name: "Arizona" },
	{ code: "ca", name: "California" },
	{ code: "co", name: "Colorado" },
	{ code: "ct", name: "Connecticut" },
	{ code: "dc", name: "District Of Columbia" },
	{ code: "de", name: "Delaware" },
	{ code: "fl", name: "Florida" },
	{ code: "ga", name: "Georgia" },
	{ code: "hi", name: "Hawaii" },
	{ code: "ia", name: "Iowa" },
	{ code: "id", name: "Idaho" },
	{ code: "il", name: "Illinois" },
	{ code: "in", name: "Indiana" },
	{ code: "ks", name: "Kansas" },
	{ code: "ky", name: "Kentucky" },
	{ code: "la", name: "Louisiana" },
	{ code: "ma", name: "Massachusetts" },
	{ code: "md", name: "Maryland" },
	{ code: "me", name: "Maine" },
	{ code: "mi", name: "Michigan" },
	{ code: "mn", name: "Minnesota" },
	{ code: "mo", name: "Missouri" },
	{ code: "ms", name: "Mississippi" },
	{ code: "mt", name: "Montana" },
	{ code: "nc", name: "North Carolina" },
	{ code: "nd", name: "North Dakota" },
	{ code: "ne", name: "Nebraska" },
	{ code: "nh", name: "New Hampshire" },
	{ code: "nj", name: "New Jersey" },
	{ code: "nm", name: "New Mexico" },
	{ code: "nv", name: "Nevada" },
	{ code: "ny", name: "New York" },
	{ code: "oh", name: "Ohio" },
	{ code: "ok", name: "Oklahoma" },
	{ code: "or", name: "Oregon" },
	{ code: "pa", name: "Pennsylvania" },
	{ code: "ri", name: "Rhode Island" },
	{ code: "sc", name: "South Carolina" },
	{ code: "sd", name: "South Dakota" },
	{ code: "tn", name: "Tennessee" },
	{ code: "tx", name: "Texas" },
	{ code: "ut", name: "Utah" },
	{ code: "va", name: "Virginia" },
	{ code: "vt", name: "Vermont" },
	{ code: "wa", name: "Washington" },
	{ code: "wi", name: "Wisconsin" },
	{ code: "wv", name: "West Virginia" },
	{ code: "wy", name: "Wyoming" },
];

const HomeStates = ({ states }) => {
	const classes = useStyles("HomeStates");

	return (
		<section className={classes.wrapper}>
			<Container maxWidth="lg" disableGutters>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					className={classes.head}
					mb={4}
				>
					<Typography component="h2" className={classes.mainH2}>
						Find {proAdjective.plural} in all 50 states
					</Typography>
				</Box>

				<Grid container spacing={4}>
					{states.map((section, index) => (
						<Grid item xs={6} sm={4} md={3} key={section.abbreviation}>
							<Link color={"secondary"} href={`/${section.abbreviation}/`}>
								{section.name.ucFirst()}
							</Link>
						</Grid>
					))}
				</Grid>

				{/* <Grid container spacing={4}>
					{(states || []).map((state) => (
						<Grid item xs={6} sm={4} md={3} key={state.abbreviation}>
							<Link color={"secondary"} href={`/${state.abbreviation}/`}>
								{state.name.ucFirst()}
							</Link>
						</Grid>
					))}
				</Grid> */}
			</Container>
		</section>
	);
};

HomeStates.propTypes = {
	states: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			abbreviation: PropTypes.string.isRequired,
		})
	),
};

export default HomeStates;
