import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import loadable from "@loadable/component";

// Hooks
import useStaticContext from "@hooks/useStaticContext";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import EmptyMessage from "@custom/EmptyMessage";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const { BRAND_NAME } = process.env;

const CardBudget = observer(({ empty }) => {
	const classes = useStyles("Home");

	const value = 33;

	const progressClasses =
		value === 100
			? {
					root: classes.lproot,
					colorPrimary: classes.lpcolorPrimary,
					bar: classes.lpbarRed,
			  }
			: {
					root: classes.lproot,
					colorPrimary: classes.lpcolorPrimary,
					bar: classes.lpbar,
			  };

	return (
		<React.Fragment>
			<div className={classes.homePaperBox}>
				<Paper variant="outlined" className={classes.homePaper}>
					<div className={classes.homeCard}>
						<div>
							<div className={classes.homeCardTitleBox}>
								<div className={classes.homeCardHeader}>
									{empty ? "Ads" : "Ads budget"}
								</div>
								{empty ? (
									<Tooltip
										title="You need at least one review to start getting new customers."
										arrow
										TransitionComponent={Zoom}
										placement={"top"}
									>
										<Chip
											color="primary"
											size="small"
											label="Disabled"
											style={{
												color: "#b71c1c",
												backgroundColor: "#ffebee",
											}}
										/>
									</Tooltip>
								) : (
									<div className={classes.metricsTitle}>This week</div>
								)}
							</div>
							{empty && (
								<>
									<Box mb={8} mt={12} display={"flex"}>
										<EmptyMessage
											header={"You're not showing up in search results."}
											body={`You only show up in search results after setting your targeting. This allows interested customers to view your profile and contact you directly.`}
											icon={<SearchIcon style={{ fontSize: 40 }} />}
											//variant={false}
											buttonName={"Set up targeting"}
											buttonLink={"/pros/services"}
											thin
											//buttonSize="large"
										/>
									</Box>
								</>
							)}
							{!empty && (
								<>
									<div className={classes.homeCardSubHeader}>
										Amount you've spent on{" "}
										<Box component={"span"} fontWeight={600}>
											12 new leads
										</Box>{" "}
										from {BRAND_NAME}
									</div>
									<Box my={6}>
										<Box
											width="100%"
											bgcolor={"#F7F7F7"}
											borderRadius={4}
											p={6}
										>
											<Box
												display={"flex"}
												alignItems={"center"}
												style={{ gap: 20 }}
											>
												<MonetizationOnIcon
													color="primary"
													style={{ fontSize: 40 }}
												/>
												<Box
													display={"flex"}
													flexDirection={"column"}
													style={{ gap: 12 }}
													width="100%"
												>
													<Box fontSize={20} fontWeight={600}>
														$650 / $800
													</Box>
													<LinearProgress
														variant="determinate"
														value={value}
														classes={progressClasses}
													/>
												</Box>
											</Box>
										</Box>
									</Box>

									{value === 100 && (
										<Alert severity="error">
											Max budget reached.{" "}
											<Link color="primary" underline="always">
												Increase
											</Link>{" "}
											to appear in search results
										</Alert>
									)}
								</>
							)}
						</div>

						{!empty && (
							<div className={classes.homeCardCTA}>
								<Link
									href="/pros/reviews"
									color={"primary"}
									className={classes.homeLink}
								>
									view settings
								</Link>
								<Box height={20}>
									<Divider light orientation="vertical" />
								</Box>
								<Link color={"primary"} className={classes.homeLink}>
									edit budget
								</Link>
							</div>
						)}
					</div>
				</Paper>
			</div>
		</React.Fragment>
	);
});

export default CardBudget;
