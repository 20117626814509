import React, { useEffect } from "react";
import { compose } from "recompose";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";
import useStaticContext from "@hooks/useStaticContext";

import SchemaOrg from "@components/Global/Custom/SchemaOrg";
import HeaderHome from "@components/Main/Shared/HeaderHome";
import Footer from "@components/Main/Shared/Footer";

import HomeBanner, {
	hydrateComponents as homeBannerHydrateComponents,
} from "./HomeBanner";
import HomeServices from "./HomeServices";
import HomeCities from "./HomeCities";
import HomeStates from "./HomeStates";
import HomeHowItWorks from "./HomeHowItWorks";
import HomeSignup from "./HomeSignup";

import schema from "./@data/schema.org";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";
import HeaderSearchDrawer from "@components/Main/Shared/HeaderSearchDrawer";

import useQuery from "@hooks/useQuery";
import useError from "@hooks/useError";

import { styles, stores } from "./.config";

const { SITE_NAME } = process.env;

const Home = compose(
	withStores(stores),
	withStyles(styles),
	observer,
)(() => {
	const { accountStore, homeStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const { authenticated } = accountStore.account;

	const { states, app } = useStaticContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const searchParams = useQuery();
	const shouldDisplaySearchDrawer =
		isMobile && !app.location?.zipcode && store.searchDrawerDisplay;

	useEffect(() => {
		const message = searchParams.get("message");
		if (message) onError({ message });
	}, [searchParams]);

	return (
		<React.Fragment>
			<HeaderHome id={"HeaderHome"} />
			{shouldDisplaySearchDrawer && (
				<HeaderSearchDrawer
					open={shouldDisplaySearchDrawer}
					service={store.service}
					onToggle={(value) => store.displaySearchDrawer(value)}
				/>
			)}

			<HomeBanner id={"HomeBanner"} />
			<HomeServices />
			<HomeHowItWorks />
			{![
				"trustedcare",
				"bettercare",
				"vety",
				"lawful",
				"talo",
				"lawnmasters",
				"dealflow",
			].includes(SITE_NAME) && <HomeCities />}
			<HomeStates states={states} />

			<HomeSignup />

			<SchemaOrg schema={schema()} />
			<Footer />
			<ErrorTemplate />
		</React.Fragment>
	);
});

export default Home;

export const hydrateComponents = [
	<HomeBanner id={"HomeBanner"} />,
	<HeaderHome id={"HeaderHome"} />,
	//...homeBannerHydrateComponents,
];
