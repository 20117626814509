import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import { styles, stores } from "./.config";
import { compose } from "recompose";

import withStyles from "@framework/src/libs/withStyles";

import {
	Container,
	Box,
	LinearProgress,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";

const { BRAND_NAME } = process.env;

import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";

function LinearProgressWithLabel(props) {
	const classes = useStyles("Onboarding");
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={3}>
				<LinearProgress
					variant="determinate"
					{...props}
					classes={{
						root: classes.lproot,
						colorPrimary: classes.lpcolorPrimary,
						bar: classes.lpbar,
					}}
				/>
			</Box>
			<Box minWidth={35}>
				<Box color={"#0e6be6"} fontWeight={600} fontSize={14}>{`${Math.round(
					props.value
				)}%`}</Box>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

const OnboardingItems = compose(withStyles(styles))(({ fullView }) => {
	const classes = useStyles("Onboarding");

	const [progress, setProgress] = React.useState(69);

	// primary

	const [showList2, setShowList2] = useState(true); // State to track list visibility

	const handleToggleList2 = () => {
		setShowList2(!showList2); // Toggle the visibility
	};

	const steps = [
		{
			icon: <AccountBoxOutlinedIcon />,
			primary: "Add your business info",
			secondary: `Add your location, hours, website, etc.`,
			link: "/pros/profile/edit-info",
			hasClose: false,
			isCompleted: true,
		},
		{
			icon: <LibraryAddCheckOutlinedIcon />,
			primary: "Set your preferences",
			secondary: `Once you set up targeting preferences you'll appear in search results and start getting direct leads.`,
			link: "/pros/services",
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <FormatListBulletedOutlinedIcon />,
			primary: "Create your bookable services",
			secondary: `Start creating your library of services to get bookings.`,
			hasClose: false,
			link: "/pros/catalog",
			isCompleted: false,
		},
		{
			icon: <CreditCardOutlinedIcon />,
			primary: "Get set up to take payments",
			secondary: `Enable payments so you can get paid when ${clientAdjective.plural} book with you.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <DateRangeOutlinedIcon />,
			primary: "Get your first booking",
			secondary: `Set up your booking channels to start getting new bookings.`,
			link: "/pros/channels",
			hasClose: false,
			isCompleted: false,
		},
	];

	// advanced

	const [showList, setShowList] = useState(false); // State to track list visibility

	const handleToggleList = () => {
		setShowList(!showList); // Toggle the visibility
	};

	const steps2 = [
		{
			icon: <PersonAddOutlinedIcon />,
			primary: "Import your customer list",
			secondary: `Import your customer information to streamline your booking process.`,
			hasClose: false,
			isCompleted: false,
		},

		{
			icon: <DateRangeOutlinedIcon />,
			primary: "Review booking settings",
			secondary: `Confirm where, when, and how you accept bookings.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <ListAltOutlinedIcon />,
			primary: "Create a test appointment",
			secondary: `Scheduling a test appointment is useful as it's the same process for booking a  ${clientAdjective.singular}'s appointment directly, instead of directing them to your booking site.`,
			link: "/pros/appointments",
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <GradeOutlinedIcon />,
			primary: "Ask existing customers for reviews",
			secondary: `Feedback builds trust and credibility, helping you attract new customers.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <PhotoOutlinedIcon />,
			primary: "Add photos and videos to your profile",
			secondary: `Boost your profile with engaging photos and videos to easily attract more clients.`,
			hasClose: false,
			isCompleted: false,
		},
		{
			icon: <WebOutlinedIcon />,
			primary: "Review and edit your free website",
			secondary: `Let your customers book online 24/7 with a 100% free, easy-to-use website that reflects your style.`,
			hasClose: false,
			isCompleted: false,
		},
	];

	return (
		<>
			<Box fontSize={25} fontWeight={700} lineHeight={1.3}>
				Welcome!
				<br /> {`Let's get you set up on ${BRAND_NAME}`}.
			</Box>
			<Box py={6}>
				<LinearProgressWithLabel value={progress} />
			</Box>

			<Box my={6}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					onClick={handleToggleList2}
					className={classes.listBox}
				>
					<Box fontSize={20} fontWeight={600} mb={3}>
						Primary setup
					</Box>

					{showList2 ? (
						<ExpandLessOutlinedIcon fontSize="large" />
					) : (
						<ExpandMoreIcon fontSize="large" />
					)}
				</Box>
				{showList2 && (
					<List>
						{steps.map((step, index) => (
							<ListItem
								button
								key={index}
								classes={{
									root: classes.listItem,
									button: classes.listButton,
									container: classes.listItemContainer,
								}}
								component="a"
								href={step.link}
							>
								{step.isCompleted ? (
									<ListItemIcon>
										<CheckIcon color="primary" />
									</ListItemIcon>
								) : (
									<ListItemIcon>{step.icon}</ListItemIcon>
								)}
								<ListItemText
									primary={step.primary}
									secondary={step.secondary}
									classes={{
										primary: classes.listPrimary,
										secondary: classes.listSecondary,
									}}
								/>
								{step.hasClose && (
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<CloseOutlinedIcon fontSize="small" />
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						))}
					</List>
				)}
			</Box>
			<Box my={6}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					onClick={handleToggleList}
					className={classes.listBox}
				>
					<Box fontSize={20} fontWeight={600} mb={3}>
						Advanced setup
					</Box>
					{showList ? (
						<ExpandLessOutlinedIcon fontSize="large" />
					) : (
						<ExpandMoreIcon fontSize="large" />
					)}
				</Box>
				{showList && (
					<List>
						{steps2.map((step, index) => (
							<ListItem
								button
								key={index}
								className={classes.listItem}
								component="a"
								href={step.link}
							>
								{step.isCompleted ? (
									<ListItemIcon>
										<CheckIcon color="primary" />
									</ListItemIcon>
								) : (
									<ListItemIcon>{step.icon}</ListItemIcon>
								)}
								<ListItemText
									primary={step.primary}
									secondary={step.secondary}
									classes={{
										primary: classes.listPrimary,
										secondary: classes.listSecondary,
									}}
								/>
								{step.hasClose && (
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<CloseOutlinedIcon fontSize="small" />
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						))}
					</List>
				)}
			</Box>
		</>
	);
});

OnboardingItems.propTypes = {
	fullView: PropTypes.bool,
};

OnboardingItems.defaultProps = {
	fullView: false,
};
export default OnboardingItems;
