const ReviewAvgRating = ({ rating }) => {

    const avgRating = rating || 0

    if (avgRating % 1 === 0) {
        return `${avgRating}.0`
    }

    return avgRating.toFixed(1)

}

export default ReviewAvgRating