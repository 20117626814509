import { observable, action, computed, toJS } from "mobx";
import ModalStore from "@src/stores/Component/ModalStore";

import { providerService } from "../@data/service";

const { getProvider } = providerService;

class ReviewsDialogStore extends ModalStore {
	constructor(rootStore, staticContext) {
		super({
			rootStore: rootStore,
			providerAccountId: staticContext.app.account._id,
		});
	}

	get textFieldStore() {
		const { textFieldStore } = this.rootStore.stores;
		return textFieldStore;
	}

	get show() {
		return super.show;
	}

	onClose(clearData) {
		super.onClose(clearData);
	}

	@observable _provider = {};
	@computed
	get provider() {
		return toJS(this._provider);
	}

	@observable _emails = [""];
	@computed
	get emails() {
		return toJS(this._emails);
	}

	async get() {
		try {
			const provider = await getProvider({
				filter: { account: this.providerAccountId },
			});

			this._provider = provider;
		} catch (err) {
			throw err;
		}
	}

	async init() {
		try {
			await this.get();
		} catch (err) {
			throw err;
		}
	}

	reset() {
		this._emails = [""];
	}

	@action
	addNewEmailInput() {
		this._emails = [...this._emails, ""];
	}

	@action
	removeEmailInputAtIndex(index) {
		this._emails.splice(index, 1);
	}

	@action
	changeEmailValueAtIndex(index, value) {
		this._emails[index] = value;
	}

	// TODO: Update
	@action
	submit() {
		try {
			if (this.textFieldStore.isValidGroup("ask-for-reviews-email") === false)
				return;
		} catch (err) {
			throw err;
		}
	}
}

export default ReviewsDialogStore;
