import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MaterialChip from "@material-ui/core/Chip";

const useStyles = makeStyles({
	chip: {
		"&.purple": {
			color: "#0535bc",
			backgroundColor: "#e7eaff",
		},
		"&.green": {
			color: "#0d8d33",
			backgroundColor: "#e2f9e9",
		},
		"&.blue": {
			color: "#0b05bc",
			backgroundColor: "#e7f4ff",
		},
		"&.grey": {
			color: "#455a64",
			backgroundColor: "#eceff1",
		},
		"&.yellow": {
			color: "#9e7431",
			backgroundColor: "#fff3e0",
		},
		"&.red": {
			color: "#b71c1c",
			backgroundColor: "#ffebee",
		},
	},
	icon: {
		"&.purple": {
			color: "#0535bc",
		},
		"&.green": {
			color: "#0d8d33",
		},
		"&.blue": {
			color: "#0b05bc",
		},
		"&.grey": {
			color: "#455a64",
		},
		"&.yellow": {
			color: "#9e7431",
		},
		"&.red": {
			color: "#b71c1c",
		},
	},
});

const Chip = ({
	classes,
	color,
	customColor,
	size,
	label,
	icon,
	clickable,
	variant,
	...props
}) => {
	if (!classes) classes = useStyles();
	return (
		<>
			<MaterialChip
				color={color}
				clickable={clickable}
				icon={icon}
				size={size}
				label={label}
				classes={classes}
				className={`${classes.chip} ${customColor}`}
				{...props}
			/>
		</>
	);
};

Chip.defaultProps = {
	icon: null,
	size: "medium",
	clickable: true,
	color: "default",
	customColor: null,
	variant: "default",
	label: "pending",
	variant: "default",
};

Chip.propTypes = {
	icon: PropTypes.element,
	label: PropTypes.string.isRequired,
	size: PropTypes.oneOf(["medium", "small"]),
	clickable: PropTypes.bool,
	color: PropTypes.oneOf(["primary", "secondary", "default"]),
	customColor: PropTypes.oneOf([
		"purple",
		"green",
		"blue",
		"grey",
		"yellow",
		"red",
	]),
	variant: PropTypes.oneOf(["default", "outlined"]),
	variant: PropTypes.oneOf(["default", "outlined"]),
};

export default Chip;
