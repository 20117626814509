import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { compose } from "recompose";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";

import withStores from "@framework/src/libs/withStores";
import { stores, styles } from "./.config";
import withStyles from "@framework/src/libs/withStyles";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import ReviewAvgRating from "./ReviewAvgRating";

const ReviewsMetrics = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(
	({
		reviews_avg,
		reviews_count,
		reviews_five_star_count,
		reviews_four_star_count,
		reviews_three_star_count,
		reviews_two_star_count,
		reviews_one_star_count,
		smMr = 14,
		xsMr = 4,
		stacked,
	}) => {
		const { reviewsMetricsStore: store } = useStores();
		const classes = useStyles("ReviewsMetrics");

		useEffect(() => {
			store.init({
				reviews_four_star_count,
				reviews_three_star_count,
				reviews_two_star_count,
				reviews_one_star_count,
				reviews_five_star_count,
				reviews_avg,
				reviews_count,
			});
		}, [
			reviews_four_star_count,
			reviews_three_star_count,
			reviews_two_star_count,
			reviews_one_star_count,
			reviews_five_star_count,
			reviews_avg,
			reviews_count,
		]);

		return (
			<Box
				display={"flex"}
				alignItems={stacked ? "flex-start" : "center"}
				justifyContent={"flex-start"}
				flexDirection={stacked ? "column" : "row"}
			>
				<Box
					mr={{ xs: xsMr, sm: smMr }}
					flexGrow={{ xs: 1, sm: 0 }}
					mb={stacked ? 8 : 0}
				>
					<Box fontSize={48} fontWeight={600}>
						<ReviewAvgRating rating={store.reviews_avg} />
					</Box>

					<Rating
						name="half-rating-read"
						value={parseFloat(store.reviews_avg)}
						precision={0.5}
						readOnly
						size={"large"}
					/>

					<Box mt={1}>
						{store.reviews_count} review{store.reviews_count === 1 ? "" : "s"}
					</Box>
				</Box>
				<Box
					display={{ xs: "flex", sm: "none", md: "flex" }}
					alignItems={"center"}
					flexDirection={"column"}
				>
					<div className={classes.reviewProgressBox}>
						<div className={classes.reviewProgressStar}>
							<div className={classes.reviewProgressStarCount}>5</div>
							<Rating
								max={1}
								size={"small"}
								name="half-rating-read"
								value={0}
								precision={0.5}
								readOnly
							/>
						</div>
						<div className={classes.reviewProgressBarBox}>
							<LinearProgress
								variant="determinate"
								value={store.percentFiveStarReviews}
								classes={{
									root: classes.progressRoot,
									bar: classes.progressBar,
									colorPrimary: classes.progressColorPrimary,
								}}
								aria-label="5-star rating percentage"
							/>
						</div>
						<div className={classes.reviewProgressPercentage}>
							{store.percentFiveStarReviews}%
						</div>
					</div>
					<div className={classes.reviewProgressBox}>
						<div className={classes.reviewProgressStar}>
							<div className={classes.reviewProgressStarCount}>4</div>
							<Rating
								max={1}
								size={"small"}
								name="half-rating-read"
								value={0}
								precision={0.5}
								readOnly
							/>
						</div>
						<div className={classes.reviewProgressBarBox}>
							<LinearProgress
								variant="determinate"
								value={store.percentFourStarReviews}
								classes={{
									root: classes.progressRoot,
									bar: classes.progressBar,
									colorPrimary: classes.progressColorPrimary,
								}}
								aria-label="4-star rating percentage"
							/>
						</div>
						<div className={classes.reviewProgressPercentage}>
							{store.percentFourStarReviews}%
						</div>
					</div>
					<div className={classes.reviewProgressBox}>
						<div className={classes.reviewProgressStar}>
							<div className={classes.reviewProgressStarCount}>3</div>
							<Rating
								max={1}
								size={"small"}
								name="half-rating-read"
								value={0}
								precision={0.5}
								readOnly
							/>
						</div>
						<div className={classes.reviewProgressBarBox}>
							<LinearProgress
								variant="determinate"
								value={store.percentThreeStarReviews}
								classes={{
									root: classes.progressRoot,
									bar: classes.progressBar,
									colorPrimary: classes.progressColorPrimary,
								}}
								aria-label="3-star rating percentage"
							/>
						</div>
						<div className={classes.reviewProgressPercentage}>
							{store.percentThreeStarReviews}%
						</div>
					</div>
					<div className={classes.reviewProgressBox}>
						<div className={classes.reviewProgressStar}>
							<div className={classes.reviewProgressStarCount}>2</div>
							<Rating
								max={1}
								size={"small"}
								name="half-rating-read"
								value={0}
								precision={0.5}
								readOnly
							/>
						</div>
						<div className={classes.reviewProgressBarBox}>
							<LinearProgress
								variant="determinate"
								value={store.percentTwoStarReviews}
								classes={{
									root: classes.progressRoot,
									bar: classes.progressBar,
									colorPrimary: classes.progressColorPrimary,
								}}
								aria-label="2-star rating percentage"
							/>
						</div>
						<div className={classes.reviewProgressPercentage}>
							{store.percentTwoStarReviews}%
						</div>
					</div>
					<div className={classes.reviewProgressBox}>
						<div className={classes.reviewProgressStar}>
							<div className={classes.reviewProgressStarCount}>1</div>
							<Rating
								max={1}
								size={"small"}
								name="half-rating-read"
								value={0}
								precision={0.5}
								readOnly
							/>
						</div>
						<div className={classes.reviewProgressBarBox}>
							<LinearProgress
								variant="determinate"
								value={store.percentOneStarReviews}
								classes={{
									root: classes.progressRoot,
									bar: classes.progressBar,
									colorPrimary: classes.progressColorPrimary,
								}}
								aria-label="1-star rating percentage"
							/>
						</div>
						<div className={classes.reviewProgressPercentage}>
							{store.percentOneStarReviews}%
						</div>
					</div>
				</Box>
			</Box>
		);
	}
);

ReviewsMetrics.propTypes = {
	reviews_avg: PropTypes.number.isRequired,
	reviews_count: PropTypes.number.isRequired,
	reviews_five_star_count: PropTypes.number.isRequired,
	reviews_four_star_count: PropTypes.number.isRequired,
	reviews_three_star_count: PropTypes.number.isRequired,
	reviews_two_star_count: PropTypes.number.isRequired,
	reviews_one_star_count: PropTypes.number.isRequired,
	smMr: PropTypes.number,
	xsMr: PropTypes.number,
	stacked: PropTypes.bool,
};

ReviewsMetrics.defaultProps = {
	stacked: false,
};

export default ReviewsMetrics;
