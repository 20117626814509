import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	cardTitle: {
		fontWeight: 600,
		fontSize: 18,
		//textTransform: "capitalize",
	},
	cardRoot: {
		"display": "flex",
		"flexDirection": "column",
		"position": "relative",
		"&:hover": {
			boxShadow:
				"0 0 4px 0 hsl(0deg 0% 8% / 14%), 0 16px 20px -5px hsl(0deg 0% 8% / 18%);",
			color: "#0e6be6 !important",
			transition: "all .2s ease",
		},
	},
	cardMedia: {
		height: 160,
		borderRadius: "8px 8px 0px 0px",
	},
	cardContent: {
		padding: 24,
	},
}));

const BoxTile = ({ title, src, className, cost, link, half }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			sm={6}
			md={half ? 6 : 4}
			lg={half ? 6 : 3}
			key={shortid.generate()}
		>
			<a href={link}>
				<Card mb={20} variant="outlined" className={classes.cardRoot}>
					<CardActionArea>
						{src && (
							<CardMedia
								className={classes.cardMedia}
								component="img"
								image={src}
								title={title}
								alt={title}
								loading={"lazy"}
								decoding="async"
								fetchpriority="auto"
							/>
						)}
						<CardContent className={classes.cardContent}>
							<div className={classes.cardTitle}>{title}</div>
						</CardContent>
					</CardActionArea>
				</Card>
			</a>
		</Grid>
	);
};

BoxTile.propTypes = {
	link: PropTypes.string.isRequired,
	className: PropTypes.string,
	src: PropTypes.string,
	title: PropTypes.string.isRequired,
	cost: PropTypes.string,
};

export default BoxTile;
