import gql from "graphql-tag";

export const providerQueryFragment = gql`
	fragment providerQueryFragment on Provider {
		_id
		company_name
		key
		image {
			hash
			value
		}
	}
`;
