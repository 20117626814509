const { BRAND_NAME, SITE_NAME, FB_HELP_LINK, SITE_PHONE } = process.env;

export default () => [
	{
		"@context": "https://schema.org",
		"@type": "Website",
		"name": BRAND_NAME,
		"url": `https://${SITE_NAME}.com/`,
	},
	{
		"@context": "https://schema.org",
		"@type": "Organization",
		"url": `https://${SITE_NAME}.com/`,
		"logo": `https://static.${SITE_NAME}.com/assets/images/desktop/logo_300x.png`,
		"name": BRAND_NAME,
		"contactPoint": [
			{
				"@type": "ContactPoint",
				"telephone": SITE_PHONE,
				"contactType": "customer service",
			},
		],
		"sameAs": [FB_HELP_LINK],
	},
];
