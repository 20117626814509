import React from "react";
import PropTypes from "prop-types";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import useStaticContext from "@hooks/useStaticContext";

const LazyLoad = ({ children, visibleByDefault, placeholder }) => {
	const { isBot } = useStaticContext();

	const DefaultPlaceholder = React.createElement(
		"div",
		{},
		React.Children.map(children, (child) =>
			React.cloneElement(child, { src: "" })
		)
	);

	return (
		<LazyLoadComponent
			placeholder={!isBot ? placeholder || DefaultPlaceholder : undefined}
			visibleByDefault={isBot || visibleByDefault}
		>
			{children}
		</LazyLoadComponent>
	);
};

LazyLoad.defaultProps = {
	visibleByDefault: false,
};

LazyLoad.propTypes = {
	visibleByDefault: PropTypes.bool,
};

export default LazyLoad;
