export default (theme) => ({
	wrapper: {
		background: "#FFF",
		//paddingTop: 30,
		paddingBottom: 80,
		paddingLeft: 60,
		paddingRight: 60,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	head: {
		paddingTop: 24,
		paddingBottom: 24,
	},
	mainH2: {
		fontSize: 36,
		lineHeight: 1.6,
		[theme.breakpoints.down("sm")]: {
			fontSize: 26,
		},
		fontWeight: 600,
	},
	cityButton: {
		"background": "#e6f1ff",
		"color": theme.palette.primary.main,
		"border": "1px solid",
		"borderColor": "rgba(255, 255, 255, 0);",
		"marginRight": 8,
		"marginBottom": 10,
		"borderRadius": 30,
		"&:hover": {
			background: "#c6e0ff",
			//border: "1px solid #0e6be6",
		},
	},
});
