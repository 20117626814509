export default (theme) => ({
	wrapper: {
		backgroundColor: "#FFF",
		padding: "100px 60px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 16px",
		},
	},
	mainH2: {
		fontSize: 48,
		lineHeight: "56px",
		[theme.breakpoints.down("sm")]: {
			fontSize: 44,
			lineHeight: "52px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 28,
			lineHeight: "36px",
		},
		fontWeight: 700,
		textAlign: "left",
		marginBottom: "16px",
	},
	joinH2: {
		fontSize: 38,
		fontWeight: 700,
		lineHeight: "46px",
		marginTop: 0,
		marginBottom: 64,
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			fontSize: 32,
			lineHeight: "38px",
		},
	},
	ul: {
		paddingLeft: 20,
	},
	quoteHead: {
		fontSize: 42,
		lineHeight: "50px",
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 0,
		paddingRight: 32,
		[theme.breakpoints.down("md")]: {
			fontSize: 34,
			lineHeight: "44px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: 26,
			lineHeight: "36px",
			paddingRight: 0,
		},
	},
	flex: {
		display: "flex",
	},
	stepH: {
		fontWeight: 600,
		fontSize: 18,
	},
	stepP: {
		fontSize: 16,
		lineHeight: 1.6,
		marginTop: 8,
	},
	stepIcon: {
		fontSize: 36,
		color: theme.palette.primary.main,
	},
});
