import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import loadable from "@loadable/component";

// Hooks
import useStaticContext from "@hooks/useStaticContext";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";

// Components
//import Background from "@components/Dashboard/Pro/Shared/Background";
import WeeklyActivity from "./WeeklyActivity";
import Today from "./Today";
import CompleteProfile from "./CompleteProfile";
import ReviewsMetrics from "../Shared/ReviewsMetrics";
import ReviewsDialog from "@components/Dashboard/Pro/Shared/ReviewsDialog";

import OnboardingMini from "../Shared/Onboarding/OnboardingMini";
import OnboardingItems from "../Shared/Onboarding/OnboardingItems";

import CardMetrics from "./CardMetrics";
import CardBudget from "./CardBudget";
import CardLeads from "./CardLeads";
import CardBookings from "./CardBookings";
import CardSales from "./CardSales";
import CardAppointments from "./CardAppointments";
import CardClasses from "./CardClasses";
import CardCustomers from "./CardCustomers";
import PlatformOnboardingDialog from "../Shared/Background/Modals/PlatformOnboardingDialog";
import CardHelp from "./CardHelp";
import CardWebsite from "./CardWebsite";

const { BRAND_NAME, SITE_NAME, ENV } = process.env;

const MissingInformation = loadable(() => import("./MissingInformation"));

const Home = observer(() => {
	const classes = useStyles("Home");

	const stores = useStores();
	const store = stores.homeStore;
	const missingInformationStore = stores.missingInformationStore;

	const openReviewsDialog = () => {
		stores.reviewsDialogStore.showModal = true;
	};

	const emptyCards = true;

	const [openDialog, setOpenDialog] = useState(false);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	return (
		<React.Fragment>
			<ReviewsDialog />
			<PlatformOnboardingDialog open={openDialog} onClose={handleCloseDialog} />

			{(ENV === "stage" || ENV === "development") && (
				<>
					<Box p={{ xs: 4, sm: 10 }}>
						<Container maxWidth={"xl"} disableGutters>
							<Grid container spacing={7}>
								<Grid item xs={12}>
									<Box mb={2}>
										<Chip
											color="primary"
											size="small"
											label="NEW"
											style={{
												color: "#0d8d33",
												backgroundColor: "#e2f9e9",
											}}
										/>

										<Box
											mt={2}
											display={"flex"}
											alignItems={{ xs: "flex-start", sm: "center" }}
											justifyContent={"space-between"}
											flexDirection={{ xs: "column", sm: "row" }}
										>
											<Box display={"flex"} flexDirection={"column"}>
												<Box display={"flex"} alignItems={"center"}>
													<Box fontWeight={600} fontSize={{ xs: 28, sm: 32 }}>
														Manage and grow your business—all in one place (for
														free!)
													</Box>
												</Box>
												<Box
													mt={2}
													fontSize={16}
													color={"#5a5a5a"}
													lineHeight={1.5}
													mr={{ xs: 0, sm: 10 }}
												>
													Easily manage your new and exiting customers, accept
													bookings, schedule appointments and classes, take
													attendance, collect payments, get leads, and get a
													free website.{" "}
													<Box component={"span"} fontWeight={600}>
														And it's subscription-free!
													</Box>
												</Box>
											</Box>
											<Box
												display={"flex"}
												alignItems={"center"}
												mt={{ xs: 4, sm: 0 }}
											>
												<Button
													size={"large"}
													color={"primary"}
													variant={"outlined"}
													disableElevation
													onClick={handleOpenDialog}
												>
													Learn more
												</Button>
											</Box>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Paper variant="outlined" className={classes.homePaper}>
										<OnboardingMini thin />
										{/* <OnboardingItems /> */}
									</Paper>
								</Grid>

								{/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<Today />
								</Grid> */}

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardSales empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardBudget empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardMetrics
										title={"Ads activity"}
										primary={"Sales"}
										secondary={"Tue, Mar 2, 2024 • 4:00 • 1hr with Paul"}
										empty={emptyCards}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardLeads empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardBookings empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardAppointments empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardClasses empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardCustomers empty={emptyCards} />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<div className={classes.homePaperBox}>
										<Paper variant="outlined" className={classes.homePaper}>
											<div className={classes.homeCard}>
												<div>
													<div className={classes.homeCardTitleBox}>
														<div className={classes.homeCardHeader}>
															Reviews
														</div>
														{store.provider.metrics.reviews_count === 0 && (
															<Tooltip
																title="You need at least one review to start getting new customers."
																arrow
																TransitionComponent={Zoom}
																placement={"top"}
															>
																<ErrorOutlineOutlinedIcon color="error" />
															</Tooltip>
														)}
													</div>
													<Box
														display={"flex"}
														alignItems={"center"}
														justifyContent={"flex-start"}
													>
														<ReviewsMetrics
															{...store.provider.metrics}
															smMr={0}
															xsMr={0}
															stacked
														/>
													</Box>
													{store.provider.metrics.reviews_count < 5 && (
														<Box
															mt={6}
															style={{ cursor: "pointer" }}
															onClick={openReviewsDialog}
														>
															<Alert severity="warning">
																<Box fontSize={16} fontWeight={600}>
																	You need more reviews
																</Box>

																<div className={classes.homeCardSubHeader}>
																	{`Reviews are a good investment — pros with at least five reviews get hired more often.`}
																</div>
															</Alert>
														</Box>
													)}
												</div>
												<div className={classes.homeCardCTA}>
													<Link
														href="/pros/reviews"
														color={"primary"}
														className={classes.homeLink}
													>
														view all
													</Link>
													<Box height={20}>
														<Divider light orientation="vertical" />
													</Box>
													<Link
														color={"primary"}
														onClick={openReviewsDialog}
														className={classes.homeLink}
													>
														get review link
													</Link>
												</div>
											</div>
										</Paper>
									</div>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<MissingInformation />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardHelp />
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
									<CardWebsite />
								</Grid>
							</Grid>
						</Container>
					</Box>
				</>
			)}

			<div className={classes.homeWrapper}>
				<Container maxWidth={false} disableGutters style={{ maxWidth: 1000 }}>
					{/* <div>
							<Alert
								severity="error"
								classes={{ message: classes.width100, root: classes.alertRoot }}
							>
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									alignItems={{ xs: "unset", sm: "unset", md: "center" }}
									flexDirection={{ xs: "column", sm: "column", md: "row" }}
								>
									<Box mb={{ xs: 5, sm: 5, md: 0 }}>
										<AlertTitle style={{ fontSize: 18, fontWeight: 700 }}>
											{`You're not showing up in search results.`}
										</AlertTitle>
										{`You only show up in search results after setting your targeting. This allows interested customers to view your profile and contact you directly, without waiting for a quote.`}
									</Box>
									<Box flexShrink={"0"} ml={{ xs: 0, sm: 0, md: 8 }}>
										<Button
											href={"/pros/services"}
											disableElevation
											style={{ backgroundColor: "#FFF" }}
											color="secondary"
											variant={"outlined"}
										>
											Set up targeting
										</Button>
									</Box>
								</Box>
							</Alert>
						</div> */}

					{/* <Box pt={8}>
							<Paper className={classes.homePaper}>
								<Box
									display={"flex"}
									alignItems={"center"}
									flexWrap={{ xs: "wrap", sm: "nowrap" }}
									p={6}
								>
									<Box
										flex={"1 1 auto"}
										mr={{ xs: 0, sm: 6 }}
										mb={{ xs: 4, sm: 0 }}
									>
										<Box display={"flex"} alignItems={"flex-start"}>
											<Box
												mr={2}
												minWidth={40}
												display={"flex"}
												alignItems={"center"}
											>
												<TrendingUpIcon
													style={{ color: "#0e6be6", fontSize: 36 }}
												/>
											</Box>
											<div>
												<Box display={"flex"} alignItems={"center"}>
													<Box
														fontWeight={700}
														mb={2}
														mr={{ xs: 0, sm: 4 }}
														fontSize={{ xs: 18, sm: 20 }}
													>
														Get 3X more leads with Targeting on
													</Box>
													<Chip label="New!" className={classes.chipGreen} />
												</Box>
												<Box
													fontSize={{ xs: 14, sm: 16 }}
													color={"grey.700"}
													lineHeight={1.5}
												>
													{`Pros who turn on targeting get boosted to the top of search results. Get up to 3X more direct leads and land more jobs today. Pause anytime.`}
												</Box>
											</div>
										</Box>
									</Box>

									<Box
										width={{ xs: "100%", sm: "auto" }}
										display={"flex"}
										alignItems={"center"}
										height={"100%"}
										flexShrink={0}
										ml={{ xs: 12 }}
									>
										<Button
											disableElevation
											size={"large"}
											color="primary"
											variant={"contained"}
											href="/pros/services"
											//onClick={openDialog}
										>
											Get started
										</Button>
									</Box>
								</Box>
							</Paper>
						</Box> */}

					<Grid container spacing={6}>
						<Grid item xs={12} md={12} lg={6}>
							<Container
								maxWidth={false}
								disableGutters
								style={{ maxWidth: 700 }}
							>
								{!store.provider.onboardingComplete && (
									<Paper className={classes.homePaper}>
										<CompleteProfile />
									</Paper>
								)}

								<Today />

								{/* {["homeguide", "fash", "tutors", "lessons", "thervo"].includes(
									SITE_NAME
								) && (
									<Paper className={classes.homePaper}>
										<WeeklyActivity />
									</Paper>
								)} */}
								{!missingInformationStore.allDone && (
									<Box mt={6}>
										<MissingInformation />
									</Box>
								)}
							</Container>
						</Grid>

						<Grid item xs={12} md={12} lg={6}>
							<Container
								maxWidth={false}
								disableGutters
								style={{ maxWidth: 700 }}
							>
								<Paper variant="outlined" className={classes.homePaper}>
									<div className={classes.homeCard}>
										<Box
											display={"flex"}
											alignItems={"flex-start"}
											justifyContent={"space-between"}
										>
											<div className={classes.homeCardHeader}>Reviews</div>
											{store.provider.metrics.reviews_count === 0 && (
												<Tooltip
													title="You need at least one review to start getting new customers."
													arrow
													TransitionComponent={Zoom}
													placement={"top"}
												>
													<ErrorOutlineOutlinedIcon color="error" />
												</Tooltip>
											)}
										</Box>
										<Box
											display={"flex"}
											alignItems={"center"}
											justifyContent={"flex-start"}
										>
											<ReviewsMetrics
												{...store.provider.metrics}
												smMr={10}
												xsMr={2}
											/>
										</Box>
										<Box mt={10}>
											<Button
												href="/pros/reviews"
												variant={"outlined"}
												color={"primary"}
											>
												Manage reviews
											</Button>
										</Box>
									</div>
								</Paper>

								{store.provider.metrics.reviews_count < 5 && (
									<Paper className={classes.homePaper}>
										<div className={classes.homeCard}>
											<Box
												display={"flex"}
												alignItems={"center"}
												justifyContent={"space-between"}
											>
												<div>
													<div className={classes.homeCardHeader}>
														You need more reviews
													</div>

													<div className={classes.homeCardSubHeader}>
														{`Reviews are a good investment — pros with at least five reviews get hired more often.`}
													</div>
												</div>
												<Box
													width={64}
													height={64}
													component={"img"}
													src={
														"//static.tutors.com/assets/images/icons/reviews-icon.svg"
													}
												/>
											</Box>
											<Box mt={10} display={"flex"} alignItems={"center"}>
												<Button
													variant={"outlined"}
													color={"primary"}
													onClick={openReviewsDialog}
												>
													Share review form
												</Button>
											</Box>
										</div>
									</Paper>
								)}
							</Container>
						</Grid>
					</Grid>
				</Container>
			</div>
		</React.Fragment>
	);
});

export default Home;
