import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { compose } from "recompose";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";

import TextField from "@components/Global/Custom/TextField";

import { useTheme } from "@material-ui/core/styles";
import useStores from "@hooks/useStores";

import withStores from "@framework/src/libs/withStores";

import { stores } from "./.config";

import useSuccess from "@hooks/useSuccess";
import useInfo from "@hooks/useInfo";

const { BRAND_NAME } = process.env;

const ReviewsDialog = compose(
	withStores(stores),
	observer
)(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const { reviewsDialogStore: store } = useStores();
	const [SuccessTemplate, onSuccess] = useSuccess({
		_id: "AskForReviews",
	});
	const [InfoTemplate, onInfo] = useInfo({
		_id: "AskForReviews",
		props: {
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			autoHideDuration: 2000,
		},
	});

	const { protocol, host } = window.location;
	const shareableLink =
		protocol + "//" + host + `/providers/${store.provider.key}/review`;

	const init = useCallback(async () => {
		await store.init();
	}, []);

	useEffect(() => {
		init();
	}, [init]);

	useEffect(() => {
		return () => store.reset();
	}, [store.show]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		await store.submit();
	};

	const handleInputChange = (index, value) => {
		store.changeEmailValueAtIndex(index, value);
	};

	const handleAddEmail = () => {
		store.addNewEmailInput();
	};

	const handleRemoveEmail = (index) => {
		store.removeEmailInputAtIndex(index);
	};

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={store.show}
			onClose={() => {
				store.onClose(true);
			}}
			aria-labelledby="Get reviews"
			aria-describedby="Get reviews"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton
						aria-label="close"
						onClick={() => {
							store.onClose(true);
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box mb={6}>
					<Box
						component={"div"}
						fontWeight={700}
						lineHeight={1.4}
						fontSize={{ xs: 22, sm: 24 }}
						pb={2}
					>
						Get reviews from past customers
					</Box>

					<Box component={"div"} mb={8} color={"grey.700"} lineHeight={1.6}>
						Ask past customers for reviews, or add online reviews you already
						have.
					</Box>
				</Box>

				<div>
					<Box component="div" mb={4} fontWeight={600}>
						Your review link:
					</Box>
					<TextField
						fullWidth
						multiline
						variant="outlined"
						minRows={2}
						value={shareableLink}
					/>
				</div>

				{/* <div>
					<form id={"ask-for-reviews"} onSubmit={handleSubmit}>
						<Box component="div" mb={2} fontWeight={700}>
							Send past customers an email
						</Box>

						{store.emails.map((el, index) => (
							<Box mb={4} key={index}>
								<TextField
									type="email"
									variant="outlined"
									fullWidth
									required
									autoCorrect="off"
									groupId={"ask-for-reviews-email"}
									id={`review-email-${index}`}
									placeholder={`Email ${index + 1}`}
									name={`review-email-${index}`}
									InputProps={{
										autoCorrect: "off",
										spellCheck: "false",
										endAdornment: store.emails.length > 1 && (
											<InputAdornment position="end">
												<IconButton
													edge="end"
													onClick={() => handleRemoveEmail(index)}
												>
													<HighlightOffOutlinedIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
									aria-label="Enter email address of past customers"
									value={el}
									onChange={(e) => handleInputChange(index, e.target.value)}
								/>
							</Box>
						))}

						<Box mb={6}>
							<Button
								size={"large"}
								color={"primary"}
								startIcon={<AddCircleOutlineIcon />}
								onClick={handleAddEmail}
							>
								Add customer
							</Button>
						</Box>

						<Box component="div" mb={2} fontWeight={600}>
							Email preview
						</Box>
						<Box
							borderRadius={4}
							boxShadow={1}
							p={6}
							bgcolor={"#FFFFFF"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							flexDirection={"column"}
						>
							<Avatar
								alt={store.provider.company_name}
								src={store.provider.image && store.provider.image.value}
								style={{ width: 80, height: 80 }}
							/>

							<Box mb={4} mt={4} fontWeight={700}>
								{store.provider.company_name}
							</Box>
							<Box component={"div"} mb={4} fontWeight={700}>
								<Rating
									name="half-rating-read"
									value={5}
									precision={0.5}
									readOnly
									size="large"
									icon={<StarBorderIcon fontSize="inherit" />}
								/>
							</Box>
							<Box component={"div"} fontSize={14} lineHeight={1.7}>
								Thanks for being a valued customer. I just signed up on{" "}
								{BRAND_NAME} to find more excellent customers like you, and
								reviews are a big part of my profile. Can you take a moment to
								write a couple sentences about working with me? I’d love if my
								future customers could hear about your experience firsthand.
								<br />
								<br />
								Thanks, {store.provider.company_name}
							</Box>
						</Box>
					</form>
				</div> */}
			</DialogContent>
			<DialogActions style={{ padding: "24px" }}>
				<CopyToClipboard
					text={shareableLink}
					onCopy={() => onSuccess({ message: "Link copied!" })}
				>
					<Button
						size={"large"}
						color={"primary"}
						variant={"contained"}
						disableElevation
						fullWidth
						startIcon={<LinkOutlinedIcon />}
					>
						Copy shareable link
					</Button>
				</CopyToClipboard>
				{/* <Button
					disableElevation
					size={"large"}
					variant={"contained"}
					color="primary"
					type={"submit"}
					form={"ask-for-reviews"}
				>
					Send request
				</Button> */}
			</DialogActions>

			<SuccessTemplate
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				autoHideDuration={2000}
			/>
			<InfoTemplate />
		</Dialog>
	);
});

ReviewsDialog.propTypes = {};
export default ReviewsDialog;
