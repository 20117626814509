import loadable from "@loadable/component";
import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@components/Global/Custom/Chip";
import CheckIcon from "@material-ui/icons/Check";

const CardUpcoming = () => {
	const classes = useStyles("Home");

	const checkedIn = false;

	return (
		<React.Fragment>
			<Box fontSize={24} mb={6} fontWeight={600}>
				Upcoming this week
			</Box>

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ButtonBase
						className={classes.buttonBase}
						href="/client/bookings/details"
					>
						<Paper variant="outlined" className={classes.paper}>
							<div className={classes.boxWrapper}>
								<div>
									<Box mb={2} fontSize={20} fontWeight={600}>
										Fry-day, April 20, 2024
									</Box>
									<Box fontSize={15} color={"grey.600"}>
										12:30 – 1:30 PM CST
									</Box>
								</div>
							</div>
							<Box p={"0px 24px 12px"}>
								<List component="nav">
									<ListItem
										button
										component={"a"}
										href="/client/bookings/details"
										disableGutters
										classes={{
											secondaryAction: classes.secondaryAction,
											container: classes.listItemContainer,
											root: classes.listItem,
										}}
									>
										<ListItemAvatar>
											<Avatar
												src="https://res.cloudinary.com/liaison-inc/image/upload/s--rpO6zc19--/c_fill,f_webp,h_400,q_auto,w_400/v1/homeguide/user-media/prod/approved/provider/5baa1a96ed809f9a1ac2898b/profile/hW3ABWdh/5baa1a96ed809f9a1ac2898b_profile.jpg"
												variant="rounded"
												//alt={provider.company_name}
												//title={provider.company_name}
												className={classes.avatar}
												imgProps={{
													loading: "lazy",
													decoding: "async",
												}}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={"420 Smoke Session"}
											secondary={"with Bob the Barber"}
											classes={{
												primary: classes.listPrimary,
												secondary: classes.listSecondary,
											}}
										/>
										<ListItemSecondaryAction className={classes.secondaryRoot}>
											{checkedIn ? (
												<Chip
													color="primary"
													icon={<CheckIcon />}
													size="small"
													label="Checked in"
													customColor={"green"}
													clickable
												/>
											) : (
												<Button
													color="primary"
													variant="contained"
													disableElevation
													fullWidth
													//endIcon={<ArrowForwardOutlinedIcon />}
													//style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
												>
													Check-in
												</Button>
											)}

											<Button
												color="primary"
												//endIcon={<ArrowForwardOutlinedIcon />}
												style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
											>
												View
											</Button>
										</ListItemSecondaryAction>
									</ListItem>

									<Box
										display={{ xs: "flex", sm: "none" }}
										alignItems={"center"}
										style={{ gap: 16 }}
									>
										{checkedIn ? (
											<Chip
												color="primary"
												icon={<CheckIcon />}
												size="small"
												label="Checked in"
												customColor={"green"}
												clickable
											/>
										) : (
											<Button
												color="primary"
												variant="contained"
												fullWidth
												disableElevation
											>
												Check in
											</Button>
										)}
										<Button
											color="primary"
											fullWidth
											style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
										>
											View
										</Button>
									</Box>
								</List>
							</Box>
						</Paper>
					</ButtonBase>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

CardUpcoming.propTypes = {};
export default CardUpcoming;

export const loader = (route) =>
	loadable(() => import("./CardUpcoming.jsx"), {
		ssr: !!route.ssr,
	});
