import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Chip from "@components/Global/Custom/Chip";
import Dialog from "@material-ui/core/Dialog";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Container from "@material-ui/core/Container";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Business } from "@material-ui/icons";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const { BRAND_NAME } = process.env;

const tutorialSteps = [
	{
		//label: `Manage and grow your business—all in one place`,
		//label: "Get everything you need to manage and grow your business",
		//Get ready to break up with multiple systems and easily manage everything in ${BRAND_NAME}.
		label: "Powerful booking software is now included for free 🎉",
		subLabel: `Easily manage all your new and exiting customers, accept bookings, schedule appointments and classes, take attendance, collect payments, and get a free website. And it's subscription-free!`,
		imgPath:
			"https://www.fresha.com/assets/_next/static/images/for-business-header-non-mobile-481436a2c8acf516dae0d0026d911793.webp",
		isFree: true,
	},
	{
		label: `Grow sales with online bookings`,
		subLabel: `Turn more leads into customers by allowing customers to book and pay instantly. Add 'Book now' buttons to your ${BRAND_NAME} profile, Instagram and Facebook pages, and website, so clients can book instantly online.`,
		imgPath: "https://cdn-partners.fresha.com/assets/7da83ecc17f2c3afe7ab.png",
		isFree: true,
	},

	{
		label: "Accept payments online and in-person",
		subLabel: `Let your new and existing clients pay securely via links, invoices,
		and saved cards online, or accept card, contactless, chip, and PIN
		payments in-person. Receive daily payouts directly to your bank
		account. Never miss a sale. Streamline your payment process with
		automated billing, reminders, and more while maintaining a
		professional image for your business.`,
		imgPath: "https://cdn-partners.fresha.com/assets/a896c925a2d102a8e3f7.png",
		isFree: true,
	},
	{
		label: "Stay organized as you scale and grow",
		subLabel:
			"Finally, a CRM that’s easy to use. Manage your leads, customers, tasks, team, sales, marketing and more.",
		imgPath:
			"https://res.cloudinary.com/liaison-inc/image/upload/v1706416887/shared/website.jpg",

		isFree: true,
	},
	{
		label: "Get a premium website—all for free 🎉",
		subLabel:
			"Stop paying thousands for a website. Get a free, better website with your booking flow embedded. Your new website is proven to attract more customers, generate more bookings, and increase your revenue—all for free.",
		imgPath:
			"https://res.cloudinary.com/liaison-inc/image/upload/v1706416887/shared/website.jpg",

		isFree: true,
	},
];

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const PlatformOnboardingDialog = ({ open, onClose, ...props }) => {
	const classes = useStyles("Background");

	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = tutorialSteps.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	//Dialog

	//const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const autoplay = false;
	const interval = 5000;
	const forceSteps = true;

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<DialogTitle style={{ minHeight: 60 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<AutoPlaySwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents
					interval={interval}
					autoplay={autoplay}
				>
					{tutorialSteps.map((step, index) => (
						<div key={step.label}>
							<Container maxWidth={"lg"} className={classes.noSelect}>
								<Box padding={6}>
									<Grid
										container
										spacing={8}
										justifyContent="center"
										alignItems="center"
										style={{ height: "65vh" }}
									>
										<Grid item xs={12} sm={6}>
											{Math.abs(activeStep - index) <= 2 ? (
												<Avatar
													style={{
														width: "100%",
														height: "100%",
														maxHeight: "60vh",
														backgroundColor: "transparent",
													}}
													variant="rounded"
													src={step.imgPath}
													alt={step.label}
												/>
											) : null}
										</Grid>

										<Grid item xs={12} sm={6}>
											<Box>
												{step.isFree && (
													<Box mb={4}>
														<Chip
															color="primary"
															size="small"
															label="Free to use"
															customColor={"green"}
															clickable
														/>
													</Box>
												)}
												<Box
													fontWeight={700}
													fontSize={{ xs: 24, sm: 48 }}
													lineHeight={{ xs: 1.3, sm: 1.2 }}
													mb={6}
												>
													{step.label}
												</Box>
												<Box fontSize={16} lineHeight={1.6}>
													{step.subLabel}
												</Box>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Container>
						</div>
					))}
				</AutoPlaySwipeableViews>
			</DialogContent>
			<DialogActions className={classes.dialogActionsTour}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					width={"100%"}
				>
					<MobileStepper
						style={{ width: "100%" }}
						steps={maxSteps}
						position="static"
						variant="dots"
						activeStep={activeStep}
						classes={{ dot: classes.dot, root: classes.stepperRoot }}
						// nextButton={
						// 	<Button
						// 		size="large"
						// 		disableElevation
						// 		variant={"contained"}
						// 		color={"primary"}
						// 		onClick={handleNext}
						// 		endIcon={
						// 			theme.direction === "rtl" ? (
						// 				<KeyboardArrowLeft />
						// 			) : (
						// 				<KeyboardArrowRight />
						// 			)
						// 		}
						// 		disabled={activeStep === maxSteps - 1}
						// 	>
						// 		Next
						// 	</Button>
						// }
						// backButton={
						// 	<Button
						// 		size="large"
						// 		color={"secondary"}
						// 		onClick={handleBack}
						// 		disabled={activeStep === 0}
						// 		startIcon={
						// 			theme.direction === "rtl" ? (
						// 				<KeyboardArrowRight />
						// 			) : (
						// 				<KeyboardArrowLeft />
						// 			)
						// 		}
						// 	>
						// 		Back
						// 	</Button>
						// }
					/>

					{forceSteps ? (
						<>
							{activeStep === maxSteps - 1 ? (
								<Box display={"flex"} justifyContent={"center"} mt={6} mb={14}>
									<Button
										style={{ minWidth: 300 }}
										disableElevation
										color="primary"
										variant="contained"
										size="large"
										onClick={onClose}
									>
										Get started
									</Button>
								</Box>
							) : (
								<Box display={"flex"} justifyContent={"center"} mt={6} mb={14}>
									<Button
										style={{ minWidth: 300 }}
										disableElevation
										color="primary"
										variant="contained"
										size="large"
										onClick={handleNext}
									>
										Next
									</Button>
								</Box>
							)}
						</>
					) : (
						<Box display={"flex"} justifyContent={"center"} mt={6} mb={14}>
							<Button
								style={{ minWidth: 300 }}
								disableElevation
								color="primary"
								variant="contained"
								size="large"
								onClick={onClose}
							>
								Get started
							</Button>
						</Box>
					)}
				</Box>
			</DialogActions>
		</Dialog>
	);
};

PlatformOnboardingDialog.propTypes = {};
export default PlatformOnboardingDialog;
