import React from "react";

import useConstants from "@hooks/useConstants";
import useStyles from "@hooks/useStyles";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import LazyLoad from "@custom/LazyLoad";

const HomeSignUp = () => {
	const classes = useStyles("HomeSignup");

	const { title, bullet_1, bullet_2, bullet_3, picture, picture_caption } =
		useConstants("HomeSignupViewModel");

	return (
		<Box
			component="section"
			className={classes.wrapper}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			bgcolor={"grey.100"}
		>
			<Container maxWidth="lg" disableGutters>
				<Grid container spacing={8} direction={"row-reverse"}>
					<Grid item xs={12} sm={6}>
						<Box
							width={"100%"}
							height={"100%"}
							display={"inline-block"}
							borderRadius={4}
						>
							<LazyLoad>
								<Avatar
									variant="rounded"
									src={picture}
									alt={picture_caption}
									imgProps={{
										decoding: "async",
										loading: "lazy",
									}}
									style={{ width: "100%", height: "100%" }}
								/>
							</LazyLoad>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							height={"100%"}
							maxWidth={500}
						>
							<div className={classes.quoteHead}>{title}</div>

							<Box mt={4}>
								<ul className={classes.ul}>
									<li>{bullet_1}</li>
									<li>{bullet_2}</li>
									<li>{bullet_3}</li>
								</ul>
							</Box>

							<Box component={"div"} mt={8}>
								<Button
									href="/pro"
									variant="contained"
									size={"large"}
									color="primary"
									disableElevation
									endIcon={<ChevronRightIcon />}
								>
									{`Learn more`}
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default HomeSignUp;
