const drawerWidth = 256;

export default (theme) => ({
	root: {
		//display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		color: theme.palette.secondary.main,
		backgroundColor: "#FFF",
		borderBottom: "1px solid #eaeaea",
		//boxShadow: "0 0 4px hsla(0,0%,76%,.35)",
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},

	drawerOpen: {
		"width": drawerWidth,
		"transition": theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shortest,
		}),
		"overflowX": "hidden",
		//"backgroundColor": "#fbfafa",
		"backgroundColor": "#f6f8fc",
		"borderRight": "1px solid #eaeaea",
		//boxShadow: "0 0 4px hsla(0,0%,76%,.35)",
		"&::-webkit-scrollbar-track": {
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar": {
			width: 18,
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgb(218 218 218 / 75%)",
			borderRadius: 8,
			border: "6px solid rgba(0, 0, 0, 0)",
			backgroundClip: "padding-box",
			borderRadius: 9999,
		},
	},
	drawerClose: {
		"transition": theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shortest,
		}),
		"overflowX": "hidden",
		"width": theme.spacing(15.5) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(15.5) + 1,
		},
		"backgroundColor": "#222",
		"&::-webkit-scrollbar-track": {
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar": {
			width: 0,
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgb(218 218 218 / 75%)",
			borderRadius: 8,
			border: "6px solid rgba(0, 0, 0, 0)",
			backgroundClip: "padding-box",
			borderRadius: 9999,
		},
	},
	drawerHidden: {
		width: "0px !important",
	},
	Toolbar: {
		paddingLeft: 16,
		paddingRight: 4,
		[theme.breakpoints.down("xs")]: {
			paddingRight: 0,
		},
	},
	toolbarSpacing: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 2),
		// necessary for content to be below app bar
		minHeight: 64,
		[theme.breakpoints.down("xs")]: {
			minHeight: 57,
		},
	},
	logo: {
		width: "120px",
		display: "block",
	},
	autocompleteRoot: {
		maxWidth: 550,
	},
	search: {
		"position": "relative",
		"borderRadius": 8,
		"marginRight": 0,
		"marginLeft": 0,
		"width": "100%",
		"backgroundColor": "#f1f3f4",
		"&:hover": {
			backgroundColor: "#e7e7e7",
		},
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(6),
			marginRight: theme.spacing(4),
			//width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 3),
		color: "rgba(0,0,0,.4)",
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 1,
	},
	inputRoot: {
		color: "inherit",
		transition:
			"box-shadow .25s cubic-bezier(.23,1,.32,1),background-color .25s cubic-bezier(.23,1,.32,1),-webkit-box-shadow .25s cubic-bezier(.23,1,.32,1)",
		width: "100%",
		paddingLeft: 12,
	},
	inputFocused: {
		backgroundColor: "#FFFFFF",
		boxShadow:
			"0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2)",
		borderRadius: 8,
	},
	inputInput: {
		// vertical padding + font size from searchIcon
		//paddingLeft: `calc(1em + 30px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			//width: '20ch',
		},
		backgroundColor: "initial",
	},
	autocompleteInput: {
		padding: "14.5px 12px 14.5px 0px !important",
	},
	autocompleteEndAdornment: {
		right: 10,
	},
	inputUnderline: {
		"&&&:before": {
			borderBottom: "none",
		},
		"&&:after": {
			borderBottom: "none",
		},
	},
	content: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		flexGrow: 1,
		//padding: theme.spacing(6),
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
			width: "100%",
		},
	},
	contentClose: {
		marginLeft: theme.spacing(14) + 1,
		flexGrow: 1,
		//padding: theme.spacing(6),
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
			width: "100%",
		},
	},
	adminList: {
		paddingRight: 8,
		//paddingLeft: 8,
		//paddingBottomg: 20,
	},
	listPanelItem: {
		"&:hover": {
			backgroundColor: "#eaebef !important",
		},
	},
	listPanelItemClosed: {
		"&:hover": {
			backgroundColor: "#727272 !important",
		},
	},
	listPanelItemNested: {
		"paddingLeft": 40,
		"&:hover": {
			backgroundColor: "#eaebef !important",
		},
	},
	listPanelItemNestedClosed: {
		"&:hover": {
			backgroundColor: "#727272 !important",
		},
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 40,
		},
	},
	adminListItemText: {
		fontWeight: 600,
	},
	adminListItemTextActive: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	adminListItemTextNested: {
		fontWeight: 400,
	},
	adminListPanelItemActive: {
		"backgroundColor": "#dce9ff !important",
		"&:hover": {
			backgroundColor: "#dce9ff !important",
		},
	},
	adminListPanelItemActiveClosed: {
		backgroundColor: "#0e6be6 !important",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: "#dce9ff !important",
		},
	},
	listPrimary: {
		fontWeight: 600,
	},
	listPrimaryDisabled: {
		fontSize: 12,
		fontWeight: 600,
		textTransform: "uppercase",
		paddingLeft: 40,
	},
	adminListItemIcon: {
		minWidth: 40,
		color: "#222222",
	},
	adminListItemIconActive: {
		minWidth: 40,
		color: theme.palette.primary.main,
	},
	adminListItemIconWhite: {
		"minWidth": 46,
		"color": "#FFF",

		[theme.breakpoints.down("sm")]: {
			color: "#222222",
		},
		"& svg": {
			fontSize: 30,
		},
	},
	adminListPanelButton: {
		borderRadius: "0 9999px 9999px 0 !important",
	},
	adminToolTips: {
		display: "none !important",
	},
	iconWrap: {
		margin: 0,
	},
	bellBadge: {
		top: 2,
		right: 3,
	},

	profile: {
		"borderRadius": 8,
		"&:hover": {
			background: "rgba(34, 34, 34, 0.04)",
		},
	},
	navBadge: {
		right: "12px !important",
		border: "2px solid #FFF",
	},
	navBadgeClosed: {
		right: 16,
		border: "2px solid #FFF",
	},
	headerIconButton: {
		padding: 8,
	},
	notificationTextPrimary: {
		fontWeight: 700,
	},
	//notificationTextSecondary: {},
	bottomNavigation: {
		width: "100%",
		position: "fixed",
		bottom: 0,
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		height: 60,
	},
	bottomNavigationRoot: {
		padding: "8px 12px 8px !important",
	},
	bottomNavigationWrapper: {
		gap: 4,
	},
	bottomNavigationLabel: {
		fontSize: 14,
		fontWeight: 600,
	},
	stepperRoot: {
		justifyContent: "center",
	},
	dot: {
		width: 12,
		height: 12,
		margin: "0 6px",
	},
	noSelect: {
		"-webkit-touch-callout": "none" /* iOS Safari */,
		"-webkit-user-select": "none" /* Safari */,
		"-khtml-user-select": "none" /* Konqueror HTML */,
		"-moz-user-select": "none" /* Old versions of Firefox */,
		"-ms-user-select": "none" /* Internet Explorer/Edge */,
		"userSelect": "none",
	},
	scrollbar: {
		"&::-webkit-scrollbar-track": {
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar": {
			width: 18,
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgb(218 218 218 / 75%)",
			borderRadius: 8,
			border: "6px solid rgba(0, 0, 0, 0)",
			backgroundClip: "padding-box",
			borderRadius: 9999,
		},
	},
});
