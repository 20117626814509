import loadable from "@loadable/component";
import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const CardBookAgain = () => {
	const classes = useStyles("Home");

	return (
		<React.Fragment>
			<Box fontSize={24} mb={6} fontWeight={600}>
				Book again
			</Box>

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Paper variant="outlined">
						<Box p={"12px 24px"}>
							<List component="nav">
								<ListItem
									button
									component={"a"}
									href="/client/bookings/details"
									disableGutters
									classes={{
										secondaryAction: classes.secondaryAction,
										container: classes.listItemContainer,
										root: classes.listItem,
									}}
								>
									<ListItemAvatar>
										<Avatar
											src="https://res.cloudinary.com/liaison-inc/image/upload/s--aUJ1x625--/c_fill,f_webp,h_400,q_auto,w_400/v1/lessons/user-media/prod/approved/provider/643ce4d09797d040d37f1b92/profile/icQkfCwT/643ce4d09797d040d37f1b92_profile.jpg"
											variant="rounded"
											//alt={provider.company_name}
											//title={provider.company_name}
											className={classes.avatar}
											imgProps={{
												loading: "lazy",
												decoding: "async",
											}}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={"Guitar lessons"}
										secondary={"with Ian Wasdin Guitar"}
										classes={{
											primary: classes.listPrimary,
											secondary: classes.listSecondary,
										}}
									/>
									<ListItemSecondaryAction className={classes.secondaryRoot}>
										<Button
											color="primary"
											//endIcon={<ArrowForwardOutlinedIcon />}
											style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
										>
											Rebook
										</Button>
									</ListItemSecondaryAction>
								</ListItem>

								<ListItem
									button
									component={"a"}
									href="/client/bookings/details"
									disableGutters
									classes={{
										secondaryAction: classes.secondaryAction,
										container: classes.listItemContainer,
										root: classes.listItem,
									}}
								>
									<ListItemAvatar>
										<Avatar
											src="https://res.cloudinary.com/liaison-inc/image/upload/s--wyjS6Hef--/c_fill,f_webp,h_400,q_auto,w_400/v1/trustedcare/user-media/prod/approved/provider/655c251cb3ab416297883067/profile/l-V9oYXZ6/o.jpg"
											variant="rounded"
											//alt={provider.company_name}
											//title={provider.company_name}
											className={classes.avatar}
											imgProps={{
												loading: "lazy",
												decoding: "async",
											}}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={"Hair cut + beard trim"}
										secondary={"with Bob the barber"}
										classes={{
											primary: classes.listPrimary,
											secondary: classes.listSecondary,
										}}
									/>
									<ListItemSecondaryAction className={classes.secondaryRoot}>
										<Button
											color="primary"
											//endIcon={<ArrowForwardOutlinedIcon />}
											style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
										>
											Rebook
										</Button>
									</ListItemSecondaryAction>
								</ListItem>
							</List>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

CardBookAgain.propTypes = {};
export default CardBookAgain;

export const loader = (route) =>
	loadable(() => import("./CardBookAgain.jsx"), {
		ssr: !!route.ssr,
	});
