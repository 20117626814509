import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import { styles, stores } from "./.config";
import { compose } from "recompose";

import withStyles from "@framework/src/libs/withStyles";

import {
	Container,
	Box,
	Paper,
	Grid,
	Divider,
	ButtonBase,
	ButtonGroup,
	LinearProgress,
	MenuItem,
	Drawer,
	IconButton,
	Avatar,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemIcon,
	ListItemSecondaryAction,
} from "@material-ui/core";

import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";

import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";

const { BRAND_NAME } = process.env;

import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";
import Onboarding from "./Onboarding";

function LinearProgressWithLabel(props) {
	const classes = useStyles("Onboarding");
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={3}>
				<LinearProgress
					variant="determinate"
					{...props}
					classes={{
						root: classes.lproot,
						colorPrimary: classes.lpcolorPrimary,
						bar: classes.lpbar,
					}}
				/>
			</Box>
			<Box minWidth={35}>
				<Box color={"#0e6be6"} fontWeight={600} fontSize={14}>{`${Math.round(
					props.value
				)}%`}</Box>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

const OnboardingMini = compose(withStyles(styles))(({ fullView, thin }) => {
	const classes = useStyles("Onboarding");

	const [progress, setProgress] = React.useState(69);

	// drawer

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setIsDrawerOpen(open);
	};

	return (
		<>
			<Paper variant="outlined">
				<ButtonBase className={classes.buttonBase} onClick={toggleDrawer(true)}>
					<Box width={"100%"} p={"24px 24px"}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							flexDirection={{ xs: "column", sm: "row" }}
							width={"100%"}
						>
							<Box textAlign={"left"}>
								<Box fontSize={20} fontWeight={600} mb={1}>
									You're 69% set up.
								</Box>
								{!thin && (
									<Box
										fontSize={15}
										color={"#5a5a5a"}
										lineHeight={1.5}
										mr={{ xs: 0, sm: 6 }}
									>
										{`${proAdjective.plural.ucFirst()} who run their business on ${BRAND_NAME} save thousands of dollars, get about 3X more leads and bookings, and make more money.`}
									</Box>
								)}
							</Box>
							<Box
								display={"flex"}
								alignItems={"center"}
								style={{ gap: 16 }}
								width={{ xs: "100%", sm: 270 }}
							>
								<Box width="100%">
									<LinearProgressWithLabel value={progress} />
								</Box>
								<IconButton
									aria-label="finish account"
									color="primary"
									//onClick={() => {store.onClose(true);}}
								>
									<ArrowForwardOutlinedIcon />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</ButtonBase>
			</Paper>

			<Drawer
				anchor="right"
				open={isDrawerOpen}
				onClose={toggleDrawer(false)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				ModalProps={{
					BackdropProps: {
						style: {
							backgroundColor: "rgba(182, 192, 205, 0.4)",
						},
					},
				}}
			>
				<Onboarding />
			</Drawer>
		</>
	);
});

OnboardingMini.propTypes = {
	fullView: PropTypes.bool,
	thin: PropTypes.bool,
};

OnboardingMini.defaultProps = {
	fullView: false,
	thin: false,
};
export default OnboardingMini;
