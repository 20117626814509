import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";

import SendIcon from "@material-ui/icons/Send";
import StarIcon from "@material-ui/icons/Star";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import InboxIcon from "@material-ui/icons/Inbox";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import Chip from "@components/Global/Custom/Chip";

const EmptyMessage = ({
	header,
	body,
	variant,
	buttonName,
	buttonLink,
	buttonSize,
	icon,
	isFree,
	onClick,
	thin,
}) => {
	return (
		<Box
			display={"flex"}
			flex={"1 1 auto"}
			height={"100%"}
			flexDirection={"column"}
			justifyContent={"center"}
		>
			<Box
				component={"div"}
				width={"100%"}
				height={"100%"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Box
					component={"div"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					maxWidth={500}
					textAlign={"center"}
					pl={4}
					pr={4}
				>
					{isFree && (
						<>
							<Box mb={4}>
								<Chip
									color="primary"
									size="small"
									label="Free to use"
									customColor={"green"}
									clickable
								/>
							</Box>
						</>
					)}

					{icon && icon}
					{variant === "messages" && (
						<Box component={"div"} maxWidth={200} height={148} m="0 auto">
							<Box
								component={"img"}
								width={"100%"}
								alt={"Lead prices"}
								src="//static.homeguide.com/assets/images/desktop/pro-messages-icon.png"
								loading={"lazy"}
							/>
						</Box>
					)}
					{variant === "unread" && <InboxIcon style={{ fontSize: 64 }} />}

					{variant === "archived" && (
						<ArchiveOutlinedIcon style={{ fontSize: 64 }} />
					)}
					{variant === "sent" && <SendIcon style={{ fontSize: 64 }} />}
					{variant === "starred" && <StarIcon style={{ fontSize: 64 }} />}
					{variant === "pending" && (
						<HourglassEmptyOutlinedIcon style={{ fontSize: 64 }} />
					)}
					{variant === "confirmed" && (
						<ThumbUpOutlinedIcon style={{ fontSize: 64 }} />
					)}
					{variant === "done" && (
						<DoneOutlineOutlinedIcon style={{ fontSize: 64 }} />
					)}
					{variant === "nothired" && (
						<NotInterestedOutlinedIcon style={{ fontSize: 64 }} />
					)}

					{variant === "leads" && (
						<Box component={"div"} maxWidth={160} height={187} m="0 auto">
							<Box
								component={"img"}
								width={"100%"}
								alt={"Lead prices"}
								src="//static.homeguide.com/assets/images/desktop/pro-trophy-icon.png"
								loading={"lazy"}
							/>
						</Box>
					)}
					{variant === "targeting" && (
						<Box component={"div"} maxWidth={120} height={122} m="0 auto">
							<Box
								component={"img"}
								width={"100%"}
								alt={"Lead prices"}
								src="//static.homeguide.com/assets/images/desktop/pro-warning-icon.png"
								loading={"lazy"}
							/>
						</Box>
					)}
					{variant === "opportunities" && (
						<Box component={"div"} maxWidth={220} height={144} m="0 auto">
							<Box
								component={"img"}
								width={"100%"}
								alt={"Lead prices"}
								src="//static.homeguide.com/assets/images/desktop/pro-paperplane-icon.png"
								loading={"lazy"}
							/>
						</Box>
					)}
					{variant === "reviews" && (
						<Box component={"div"} maxWidth={200} height={100} m="0 auto">
							<Box
								component={"img"}
								width={"100%"}
								alt={"Lead prices"}
								src="//static.homeguide.com/assets/images/desktop/pro-reviews-icon.png"
								loading={"lazy"}
							/>
						</Box>
					)}

					<Box
						fontSize={22}
						lineHeight={1.4}
						fontWeight={thin ? 600 : 700}
						mt={5}
					>
						{header}
					</Box>
					<Box fontSize={14} lineHeight={1.5} mt={3} color={"grey.800"}>
						{body}
					</Box>
					{buttonName && (
						<Box mt={6}>
							<Button
								variant={"contained"}
								disableElevation
								href={buttonLink}
								color="primary"
								size={buttonSize}
								onClick={onClick}
							>
								{buttonName}
							</Button>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

EmptyMessage.defaultProps = {
	variant: null,
	header: "You have no unread messages.",
	body: null,
	buttonName: null,
	buttonLink: null,
	buttonSize: "medium",
	icon: null,
	isFree: false,
	thin: false,
	onClick: () => {},
};

EmptyMessage.propTypes = {
	variant: PropTypes.oneOf([
		"messages",
		"unread",
		"reviews",
		"archived",
		"sent",
		"starred",
		"pending",
		"confirmed",
		"done",
		"nothired",
		"leads",
		"targeting",
		"opportunities",
	]),
	header: PropTypes.string,
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	buttonName: PropTypes.string,
	buttonLink: PropTypes.string,
	buttonSize: PropTypes.string,
	isFree: PropTypes.bool,
	thin: PropTypes.bool,
	onClick: PropTypes.func,
};
export default EmptyMessage;
