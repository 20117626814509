import React from "react";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// MUI Icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const CompleteProfile = () => {
	const classes = useStyles("Home");
	const { homeStore: store } = useStores();

	return (
		<div className={classes.homeCard}>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<div>
					<div className={classes.homeCardHeader}>
						<ErrorOutlineIcon color="error" />
						<Box ml={2}>Customers can't find your profile yet.</Box>
					</div>

					<div className={classes.homeCardSubHeader}>
						{`You’ll be listed soon! Just add the last few details to your profile so new customers can contact you.`}
					</div>
				</div>
				<Box
					width={64}
					height={64}
					component={"img"}
					src={"//static.tutors.com/assets/images/icons/new-customers.png"}
				/>
			</Box>
			<Box mt={10} display={"flex"} alignItems={"center"}>
				<Button variant={"outlined"} color={"primary"} href={"/pros/profile"}>
					Complete your profile
				</Button>
			</Box>
		</div>
	);
};

export default CompleteProfile;
