import { observable, action, computed } from "mobx";

class ModalStore {
	@observable
	currentModal = "";

	data = {};

	@observable showModal = false;
	@observable fullWidth = true;
	@observable maxWidth = "md";

	constructor(options) {
		this.onClose = this.onClose.bind(this);
		Object.assign(this, options);
	}

	get show() {
		return this.showModal;
	}

	@computed set show(val) {
		this.showModal = val;
	}

	close() {
		this.show = false;
	}

	setData(key, value) {
		this.data[key] = value;
		this[key] = value;
	}

	clearData() {
		Object.keys(this.data).forEach((key) => (this[key] = undefined));
		this.data = {};
	}

	@action onClose(event, reason) {
		this.clearData();
		this.showModal = false;
	}
}

export default ModalStore;
