import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		"background": "#e6f1ff",
		"color": theme.palette.primary.main,
		"border": "1px solid",
		"borderColor": "rgba(255, 255, 255, 0);",
		"marginRight": 8,
		"borderRadius": 30,
		"&:hover": {
			background: "#c6e0ff",
			//border: "1px solid #0e6be6",
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: 10,
		},
	},
}));

const Navigation = ({ link, title, onClick }) => {
	const classes = useStyles();

	if (onClick) {
		return (
			<Button
				key={shortid.generate()}
				onClick={onClick}
				variant="contained"
				className={classes.root}
				disableElevation
			>
				{title}
			</Button>
		);
	}

	return (
		<Button
			key={shortid.generate()}
			href={link}
			variant="contained"
			className={classes.root}
			disableElevation
		>
			{title}
		</Button>
	);
};

Navigation.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	onClick: PropTypes.func,
};

export default Navigation;
