import React, { useEffect } from "react";

// Hooks
import useStyles from "@hooks/useStyles";
import useStaticContext from "@libs/framework/src/hooks/useStaticContext";
import useStores from "@hooks/useStores";
import useLoading from "@hooks/useLoading";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

// MUI Icons
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { observer } from "mobx-react";

const Today = observer(({}) => {
	const classes = useStyles("Home");
	const { todayStore: store } = useStores();

	return store.displayEmptyState ? (
		<div className={classes.homePaperBox}>
			<Paper variant="outlined" className={classes.homePaper}>
				<div className={classes.homeCard}>
					<Box
						height={200}
						display="flex"
						alignContent={"center"}
						alignItems="center"
						justifyContent={"center"}
						flexDirection="column"
					>
						<CheckCircleOutlineOutlinedIcon fontSize="large" />
						<Box
							my={6}
							fontSize={14}
							lineHeight={1.4}
							maxWidth={200}
							textAlign="center"
						>
							You don’t have any new leads or messages right now.
						</Box>
					</Box>
				</div>
			</Paper>
		</div>
	) : (
		<>
			<div className={classes.homePaperBox}>
				<Paper variant="outlined" className={classes.homePaper}>
					<div className={classes.homeCard}>
						<div>
							<div className={classes.homeCardTitleBox}>
								<div className={classes.homeCardHeader}>Today</div>

								{/* <Tooltip
								title="You need at least one review to start getting new customers."
								arrow
								TransitionComponent={Zoom}
								placement={"top"}
							>
								<ErrorOutlineOutlinedIcon color="error" />
							</Tooltip> */}
							</div>

							<List component="nav" aria-label="Activity">
								{store.unreadInboxMessagesCount > 0 && (
									<ListItem
										component={"a"}
										button
										href={"/pros/inbox"}
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<SmsOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary={
												<div>
													<Box component={"span"} fontWeight={600}>
														{`${
															store.unreadInboxMessagesCount > 10
																? 10 + "+"
																: store.unreadInboxMessagesCount
														} unread message${
															store.unreadInboxMessagesCount > 1 ? "s" : ""
														}`}
													</Box>{" "}
													in your inbox
												</div>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												href={"/pros/inbox"}
												color={"primary"}
												edge="end"
											>
												<ArrowForwardOutlinedIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)}
								{store.unacceptedDirectLeadsCount > 0 && (
									<ListItem
										component={"a"}
										button
										href={"/pros/leads"}
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<MyLocationOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary={
												<div>
													<Box component={"span"} fontWeight={600}>
														{store.unacceptedDirectLeadsCount > 10
															? 10 + "+"
															: store.unacceptedDirectLeadsCount}{" "}
														direct lead
														{store.unacceptedDirectLeadsCount > 1 && "s"}
													</Box>{" "}
													need{store.unacceptedDirectLeadsCount === 1 && "s"} a
													response
												</div>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												href={"/pros/leads"}
												color={"primary"}
												edge="end"
											>
												<ArrowForwardOutlinedIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)}
								{store.opportunitiesCount > 0 && (
									<ListItem
										component={"a"}
										button
										href={"/pros/leads/matched-leads"}
										className={classes.homeListItem}
										disableGutters
									>
										<ListItemIcon className={classes.homeListItemIcon}>
											<SendOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary={
												<div>
													<Box component={"span"} fontWeight={600}>
														{store.opportunitiesCount > 10
															? 10 + "+"
															: store.opportunitiesCount}{" "}
														{store.opportunitiesCount > 1
															? "job opportunities"
															: "job opportunity"}
													</Box>{" "}
													{store.opportunitiesCount > 1 ? "are" : "is"}{" "}
													available
												</div>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												href={"/pros/leads/matched-leads"}
												color={"primary"}
												edge="end"
											>
												<ArrowForwardOutlinedIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)}
							</List>
						</div>
					</div>
				</Paper>
			</div>
		</>
	);
});

export default Today;
