import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let HomeIcon = (props) => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<path d="M12 3L4 9v12h16V9l-8-6zm6 16h-3v-6H9v6H6v-9l6-4.5 6 4.5v9z" />
		<path d="M0 0h24v24H0V0z" fill="none" />
	</SvgIcon>
);
HomeIcon = memo(HomeIcon);
HomeIcon.displayName = "HomeIcon";
HomeIcon.muiName = "HomeIcon";

export default HomeIcon;
