export default (theme) => ({
	lproot: {
		height: 12,
		borderRadius: 10,
	},
	lpcolorPrimary: {
		backgroundColor: "#CCE1FF",
	},
	lpbar: {
		borderRadius: 10,
		backgroundColor: "#0e6be6",
	},
	listBox: {
		"cursor": "pointer",
		"&:hover": {
			color: "#0e6be6",
		},
	},
	listItem: {
		borderTop: "1px solid #ddd",
		paddingTop: 12,
		paddingBottom: 12,
		// "&:hover": {
		// 	backgroundColor: "rgb(234 246 255)",
		// 	borderRadius: 8,
		// },
	},
	listItemContainer: {
		"borderBottom": "1px solid rgba(0, 0, 0, 0.08)",
		"&:last-child": {
			borderBottom: "unset",
		},
	},
	listPrimary: {
		fontWeight: 600,
	},
	buttonBase: {
		"width": "100%",
		"borderRadius": 8,
		"&:hover": {
			backgroundColor: "#f6fbff",
		},
	},
});
