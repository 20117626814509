import React, { useEffect } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { compose } from "recompose";

import useStores from "@hooks/useStores";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import SnackBar from "./Shared/SnackBar";

import { styles, stores } from "./.config";

const SuccessSnackbar = ({
	store,
	transitionDuration,
	autoHideDuration,
	...props
}) => (
	<SnackBar
		transitionDuration={transitionDuration}
		autoHideDuration={autoHideDuration}
		snackbarStore={store}
		onClose={() => store.onClose()}
		{...props}
	/>
);

SuccessSnackbar.defaultProps = {
	transitionDuration: { enter: 300, exit: 300 },
	autoHideDuration: 1000,
};

SuccessSnackbar.propTypes = {
	store: PropTypes.object.isRequired,
	autoHideDuration: PropTypes.number,
	transitionDuration: PropTypes.shape({
		enter: PropTypes.number,
		exit: PropTypes.number,
	}),
};

const SuccessBanner = ({ message }) => (
	<React.Fragment>
		{message && (
			<React.Fragment>
				<div className="alert alert-info" role="alert">
					<i className="fa fa-info-circle" /> {message}
				</div>
			</React.Fragment>
		)}
	</React.Fragment>
);

SuccessBanner.propTypes = {
	message: PropTypes.string.isRequired,
};

const Success = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ store, banner, ...props }) => {
	const { alertStore } = useStores();

	useEffect(() => {
		alertStore.init(store);
		return () => alertStore.remove(store);
	}, []);

	useEffect(() => {}, [store.show]);

	if (banner === true) {
		return <SuccessBanner {...props} message={store.message} />;
	}

	return <SuccessSnackbar {...props} store={store} />;
});

Success.propTypes = {
	store: PropTypes.object.isRequired,
	banner: PropTypes.bool,
	autoHideDuration: PropTypes.number,
	transitionDuration: PropTypes.shape({
		enter: PropTypes.number,
		exit: PropTypes.number,
	}),
};

export default Success;
