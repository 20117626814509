export default (theme) => ({
	wrapper: {
		background: "#fafafa",
		paddingBottom: 30,
		paddingLeft: 60,
		paddingRight: 60,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	head: {
		paddingTop: 24,
		paddingBottom: 24,
	},
	mainH2: {
		fontSize: 36,
		lineHeight: 1.6,
		[theme.breakpoints.down("sm")]: {
			fontSize: 26,
		},
		fontWeight: 600,
	},
	gridWrap: {
		marginBottom: 50,
	},
	cardTitle: {
		fontWeight: 700,
	},
});
