import React from "react";
import shortid from "shortid";

import useConstants from "@hooks/useConstants";
import useStyles from "@hooks/useStyles";

import BoxTile from "@components/Global/Custom/BoxTile";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

import Button from "@material-ui/core/Button";
import LazyLoad from "@custom/LazyLoad";

const HomeServices = () => {
	const classes = useStyles("HomeServices");

	const { sections } = useConstants("HomeServicesViewModel");

	return (
		<React.Fragment>
			<div className={classes.wrapper}>
				<Container maxWidth="lg" disableGutters>
					{sections.map((section) => (
						<React.Fragment key={shortid.generate()}>
							{section.featured ? (
								<Box py={14}>
									<Grid container spacing={8} direction={"row-reverse"}>
										<Grid item xs={12} sm={6}>
											<Box
												width={"100%"}
												height={"100%"}
												display={"inline-block"}
												borderRadius={4}
											>
												<a href={section.link}>
													<LazyLoad>
														<Avatar
															variant="rounded"
															src={section.featured}
															alt={section.featured_alt}
															style={{ width: "100%", height: "100%" }}
															imgProps={{
																decoding: "async",
																loading: "lazy",
																fetchpriority: "auto",
															}}
														/>
													</LazyLoad>
												</a>
											</Box>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Box
												display={"flex"}
												flexDirection={"column"}
												justifyContent={"center"}
												height={"100%"}
												maxWidth={510}
											>
												{section.link ? (
													<a href={section.link}>
														<h2 className={classes.mainH2}>{section.title}</h2>
													</a>
												) : (
													<h2 className={classes.mainH2}>{section.title}</h2>
												)}

												{section.subTitle && (
													<Box mt={4}>
														<div className={classes.subTitleBox}>
															{section.subTitle}
														</div>
													</Box>
												)}

												{section.notile ? (
													<Grid container spacing={4}>
														{section.services.map((service, index) => (
															<Grid item xs={6} key={service.link}>
																<Link color={"secondary"} href={service.link}>
																	{service.title}
																</Link>
															</Grid>
														))}
													</Grid>
												) : (
													<>
														<Grid
															container
															spacing={8}
															className={classes.gridWrap}
															key={shortid.generate()}
														>
															{section.services.map((service, index) => (
																<BoxTile
																	key={shortid.generate()}
																	title={service.title}
																	src={service.src}
																	link={service.link}
																	half={true}
																/>
															))}
														</Grid>
													</>
												)}
											</Box>
										</Grid>
									</Grid>
								</Box>
							) : (
								<>
									<div className={classes.head}>
										{section.link ? (
											<a href={section.link}>
												<h2 className={classes.mainH2}>{section.title}</h2>
											</a>
										) : (
											<h2 className={classes.mainH2}>{section.title}</h2>
										)}
										{section.link && (
											<span className={classes.seeAll}>
												<Button
													href={section.link}
													color="primary"
													className={classes.button}
													endIcon={<ChevronRightOutlinedIcon />}
												>
													See all
												</Button>
											</span>
										)}
									</div>

									{section.subTitle && (
										<div className={classes.subTitleBox}>
											{section.subTitle}
										</div>
									)}

									{section.notile ? (
										<Box mb={20}>
											<Grid container spacing={4}>
												{section.services.map((service, index) => (
													<Grid item xs={6} sm={4} md={3} key={service.link}>
														<Link color={"secondary"} href={service.link}>
															{service.title}
														</Link>
													</Grid>
												))}
											</Grid>
										</Box>
									) : (
										<>
											{[
												...Array(
													Math.floor(section.services.length / 8)
												).keys(),
											].map((index) => (
												<Grid
													container
													spacing={8}
													className={classes.gridWrap}
													key={shortid.generate()}
												>
													{section.services
														.slice(index * 8, 8 + index * 8)
														.map((service) => (
															<BoxTile
																key={shortid.generate()}
																title={service.title}
																src={service.src}
																link={service.link}
															/>
														))}
												</Grid>
											))}
										</>
									)}
								</>
							)}
						</React.Fragment>
					))}
				</Container>
			</div>
		</React.Fragment>
	);
};

HomeServices.propTypes = {};

export default HomeServices;
