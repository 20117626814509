import React from "react";
import { observer } from "mobx-react";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

// MUI Icons

import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const { BRAND_NAME, LINK_HELP } = process.env;

const CardWebsite = observer(() => {
	const classes = useStyles("Home");

	return (
		<>
			<div className={classes.homePaperBox}>
				<Paper variant="outlined" className={classes.homePaper}>
					<div className={classes.homeCard}>
						<div>
							<div className={classes.homeCardTitleBox}>
								<div
									className={classes.homeCardHeader}
								>{`Your free website is ready! 🎉`}</div>
							</div>
							<div className={classes.homeCardSubHeader}>
								{`We've built you a premium website that is subscription free.`}
							</div>

							<Box my={8}>
								<a href="" target="_blank">
									<Box
										component={"img"}
										src="https://dashboard-production-f.squarecdn.com/dashboard/uploads/ecom/widget-theme-other.jpg?width=800&fit=bounds&enable=upscale"
										width={"100%"}
										borderRadius={8}
									/>
								</a>
							</Box>

							<div className={classes.homeCardSubHeader}>
								{`Pick your theme and start getting online bookings in minutes.`}
							</div>
						</div>
						<div className={classes.homeCardCTA}>
							<Button
								color="primary"
								size="large"
								fullWidth
								variant="contained"
								disableElevation
							>
								Preview site
							</Button>
						</div>
					</div>
				</Paper>
			</div>
		</>
	);
});

export default CardWebsite;
