import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import loadable from "@loadable/component";

// Hooks
import useStaticContext from "@hooks/useStaticContext";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import EmptyMessage from "@custom/EmptyMessage";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const { BRAND_NAME, SITE_NAME, ENV } = process.env;

const CardAppointments = observer(({ empty }) => {
	const classes = useStyles("Home");
	const avatar = false;
	const isFree = true;

	return (
		<React.Fragment>
			<div className={classes.homePaperBox}>
				<Paper variant="outlined" className={classes.homePaper}>
					<div className={classes.homeCard}>
						<div>
							<div className={classes.homeCardTitleBox}>
								<div className={classes.homeCardHeader}>Appointments</div>
								{empty && isFree && (
									<Tooltip
										title="You need at least one review to start getting new customers."
										arrow
										TransitionComponent={Zoom}
										placement={"top"}
									>
										<Chip
											color="primary"
											size="small"
											label="Free to use"
											style={{ color: "#0d8d33", backgroundColor: "#e2f9e9" }}
										/>
									</Tooltip>
								)}
							</div>
							{!empty && (
								<>
									<Box mb={6}>
										<div className={classes.metricsValue}>7</div>
										<div className={classes.metricsTitle}>Today</div>
									</Box>

									<Box fontSize={17} fontWeight={600}>
										Upcoming appointments
									</Box>
								</>
							)}

							{empty ? (
								<>
									<Box mb={8} mt={12} display={"flex"}>
										<EmptyMessage
											header={"Schedule in just a few clicks"}
											body={`Everything you need to book and schedule appointments with new and existing customers.`}
											icon={<ListAltOutlinedIcon style={{ fontSize: 40 }} />}
											//variant={false}
											buttonName={"Set up appointments"}
											buttonLink={"/pros/services"}
											thin
											//buttonSize="large"
										/>
									</Box>
								</>
							) : (
								<List component="nav">
									{[...Array(4)].map((_, index) => (
										<ListItem key={index} button disableGutters>
											<ListItemAvatar>
												{avatar ? (
													<Avatar
														src={``}
														imgProps={{
															loading: "lazy",
															decoding: "async",
														}}
													>
														JD
													</Avatar>
												) : (
													<>
														<div className={classes.listPrimary}>29</div>
														<Box fontSize={14}>Mar</Box>
													</>
												)}
											</ListItemAvatar>
											<ListItemText
												primary={"AC Tune-up"}
												secondary={"Fri 3:30 PM • 1hr with Paul"}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
										</ListItem>
									))}
								</List>
							)}

							{!empty && (
								<div className={classes.homeCardCTA}>
									<Link
										href="/pros/reviews"
										color={"primary"}
										className={classes.homeLink}
									>
										view all
									</Link>
									<Box height={20}>
										<Divider light orientation="vertical" />
									</Box>
									<Link color={"primary"} className={classes.homeLink}>
										edit
									</Link>
								</div>
							)}
						</div>
					</div>
				</Paper>
			</div>
		</React.Fragment>
	);
});

export default CardAppointments;
