import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import loadable from "@loadable/component";

// Hooks
import useStaticContext from "@hooks/useStaticContext";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Alert, AlertTitle } from "@material-ui/lab";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import PeopleIcon from "@material-ui/icons/People";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkIcon from "@material-ui/icons/Link";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import NearMeIcon from "@material-ui/icons/NearMe";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const { BRAND_NAME, SITE_NAME, ENV } = process.env;

const CardMetrics = observer(
	({ title, subTitle, number, numberText, avatar, primary, secondary }) => {
		const classes = useStyles("Home");

		return (
			<React.Fragment>
				<div className={classes.homePaperBox}>
					<Paper variant="outlined" className={classes.homePaper}>
						<div className={classes.homeCard}>
							<div>
								<div className={classes.homeCardTitleBox}>
									<Box
										display={"flex"}
										alignItems={"center"}
										style={{ gap: 12 }}
									>
										<div className={classes.homeCardHeader}>Ads activity</div>
										<Tooltip
											title="Metrics are updated daily"
											arrow
											TransitionComponent={Zoom}
											placement={"top"}
										>
											<HelpOutlineIcon
												color="disabled"
												style={{ fontSize: 18 }}
											/>
										</Tooltip>
									</Box>

									<div className={classes.metricsTitle}>Last 30 days</div>
									{/* <Tooltip
								title="You need at least one review to start getting new customers."
								arrow
								TransitionComponent={Zoom}
								placement={"top"}
							>
								<ErrorOutlineOutlinedIcon color="error" />
							</Tooltip> */}
								</div>

								<List>
									<Tooltip
										title="The number of customers that could have seen your listing."
										TransitionComponent={Zoom}
										arrow
										placement="top"
									>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemAvatar>
												<div className={classes.metricsIconBox}>
													<PeopleIcon className={classes.metricsIcon} />
												</div>
											</ListItemAvatar>
											<ListItemText
												primary="Reach"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"success.main"}
															fontWeight={600}
														>
															<ArrowDropUpIcon />
															44.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>1,430+</div>
										</ListItem>
									</Tooltip>

									<Tooltip
										title="The number of customers that saw your listing."
										TransitionComponent={Zoom}
										arrow
										placement="top"
									>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemAvatar>
												<div className={classes.metricsIconBox}>
													<VisibilityIcon className={classes.metricsIcon} />
												</div>
											</ListItemAvatar>
											<ListItemText
												primary="Impressions"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"success.main"}
															fontWeight={600}
														>
															<ArrowDropUpIcon />
															44.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>260</div>
										</ListItem>
									</Tooltip>

									<Tooltip
										title="The number of customers who viewed your listing."
										TransitionComponent={Zoom}
										arrow
										placement="top"
									>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemAvatar>
												<div className={classes.metricsIconBox}>
													<NearMeIcon className={classes.metricsIcon} />
												</div>
											</ListItemAvatar>
											<ListItemText
												primary="Clicks"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"success.main"}
															fontWeight={600}
														>
															<ArrowDropUpIcon />
															44.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>21</div>
										</ListItem>
									</Tooltip>

									<Tooltip
										title="The number of customers who expressed interest in your business after viewing your listing."
										TransitionComponent={Zoom}
										arrow
										placement="top"
									>
										<ListItem
											button
											disableGutters
											classes={{ root: classes.cardNumbersRoot }}
										>
											<ListItemAvatar>
												<div className={classes.metricsIconBox}>
													<PersonAddIcon className={classes.metricsIcon} />
												</div>
											</ListItemAvatar>
											<ListItemText
												primary="Leads"
												secondary={
													<>
														<Box
															display={"flex"}
															alignItems={"center"}
															color={"error.main"}
															fontWeight={600}
														>
															<ArrowDropDownIcon />
															74.5%
														</Box>
													</>
												}
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
											/>
											<div className={classes.cardNumbersValue}>8</div>
										</ListItem>
									</Tooltip>
								</List>
							</div>
							{/* <div className={classes.homeCardCTA}>
								<Link
									href="/pros/reviews"
									color={"primary"}
									className={classes.homeLink}
								>
									view all
								</Link>
								<Box height={20}>
									<Divider light orientation="vertical" />
								</Box>
								<Link color={"primary"} className={classes.homeLink}>
									take payment
								</Link>
								<Box height={20}>
									<Divider light orientation="vertical" />
								</Box>
								<Link color={"primary"} className={classes.homeLink}>
									see payouts
								</Link>
							</div> */}
						</div>
					</Paper>
				</div>
			</React.Fragment>
		);
	}
);

export default CardMetrics;
