import { parseConstants, parseStyles, parseStores } from "@src/libs/parser";

export const stores = parseStores(
	require.context("./", true, /\.(store)\.(js)$/)
);

export const styles = parseStyles(
	require.context("./", true, /\.(styles)\.(js)$/)
);

export const constants = parseConstants(
	require.context("./", true, /\.(json)$/)
);
