import React from "react";
import { adjective as proAdjective } from "@language/provider";

import useConstants from "@hooks/useConstants";
import useStyles from "@hooks/useStyles";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import FindInPageIcon from "@material-ui/icons/FindInPage";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import LazyLoad from "@custom/LazyLoad";

const HomeHowItWorks = () => {
	const classes = useStyles("HomeHowItWorks");

	const {
		title,
		picture,
		picture_caption,
		fact_1,
		bullet_1,
		fact_2,
		bullet_2,
		fact_3,
		bullet_3,
	} = useConstants("HomeHowItWorksViewModel");

	return (
		<React.Fragment>
			<Box
				component="section"
				className={classes.wrapper}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				bgcolor={"grey.100"}
			>
				<Container maxWidth="lg" disableGutters>
					<Grid container spacing={8} direction={"row-reverse"}>
						<Grid item xs={12} sm={6}>
							<Box
								width={"100%"}
								height={"100%"}
								display={"inline-block"}
								borderRadius={4}
							>
								<LazyLoad>
									<Avatar
										variant="rounded"
										src={picture}
										alt={picture_caption}
										style={{ width: "100%", height: "100%" }}
										imgProps={{
											decoding: "async",
											loading: "lazy",
										}}
									/>
								</LazyLoad>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box
								display={"flex"}
								flexDirection={"column"}
								justifyContent={"center"}
								height={"100%"}
								maxWidth={510}
							>
								<h2 className={classes.quoteHead}>{title}</h2>

								<Box mt={10}>
									<Grid container spacing={8}>
										<Grid item xs={12}>
											<div className={classes.flex}>
												<FindInPageIcon className={classes.stepIcon} />
												<Box ml={4}>
													<div className={classes.stepH}>
														{fact_1 ? fact_1 : "Search"}
													</div>
													<div className={classes.stepP}>
														{bullet_1
															? bullet_1
															: `Search, get cost estimates, contact ${proAdjective.plural}, and book—all for free.`}
													</div>
												</Box>
											</div>
										</Grid>

										<Grid item xs={12}>
											<div className={classes.flex}>
												<SupervisedUserCircleIcon
													className={classes.stepIcon}
												/>

												<Box ml={4}>
													<div className={classes.stepH}>
														{fact_2 ? fact_2 : `Compare`}
													</div>
													<div className={classes.stepP}>
														{bullet_2
															? bullet_2
															: "View profiles, read reviews, check qualifications, and see prices before hiring."}
													</div>
												</Box>
											</div>
										</Grid>

										<Grid item xs={12}>
											<div className={classes.flex}>
												<BeenhereIcon className={classes.stepIcon} />

												<Box ml={4}>
													<div className={classes.stepH}>
														{fact_3 ? fact_3 : "Hire"}
													</div>
													<div className={classes.stepP}>
														{bullet_3
															? bullet_3
															: `Ask questions, confirm their availability, and hire the right ${proAdjective.singular} when you're ready.`}
													</div>
												</Box>
											</div>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</React.Fragment>
	);
};

export default HomeHowItWorks;
