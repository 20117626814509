export default (theme) => ({
	progressRoot: {
		borderRadius: 5,
		height: 8,
	},
	progressBar: {
		borderRadius: 5,
		backgroundColor: "#ff6474",
	},
	progressColorPrimary: {
		borderRadius: 5,
		backgroundColor: theme.palette.grey[200],
	},
	reviewProgressBox: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		marginBottom: 8,
	},
	reviewProgressStar: {
		display: "flex",
		alignItems: "center",
		minWidth: 30,
	},
	reviewProgressStarCount: {
		display: "flex",
		alignItems: "center",
		minWidth: 12,
	},
	reviewProgressBarBox: {
		marginLeft: 8,
		marginRight: 8,
		width: 170,
		[theme.breakpoints.down("xs")]: {
			width: 100,
		},
	},
	reviewProgressPercentage: {
		width: 22,
	},
});
