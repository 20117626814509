import { observable } from "mobx";

import rootService from "../@data/service";
const { getService } = rootService;

class HomeStore {
	@observable searchDrawerDisplay = false;
	@observable service;

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.staticContext = staticContext;
	}

	get zipcode() {
		const { searchBarStore } = this.rootStore.stores;
		return searchBarStore.findInstanceById("search-bar")?.zipcode;
	}

	displaySearchDrawer(value) {
		this.searchDrawerDisplay = value;
	}

	updateService(service) {
		this.service = service;
	}

	async getService(serviceName) {
		try {
			const service = await getService({
				filter: { OR: [{ name: serviceName }, { name_proper: serviceName }] },
			});
			console.log({ service });
			return service;
		} catch (error) {
			throw error;
		}
	}
}

export default HomeStore;
