import React from "react";
import shortid from "shortid";

import useConstants from "@hooks/useConstants";
import useStyles from "@hooks/useStyles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SearchBar from "../Shared/SearchBar";
import Navigation from "@components/Global/Custom/Navigation";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

const HomeBanner = ({ id }) => {
	const { homeStore: store } = useStores();
	const [ErrorTemplate, onError] = useError({ _id: "HomeBannerError" });
	const classes = useStyles("HomeBanner");
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));

	const {
		background,
		title,
		subtitle,
		searchBar,
		searchPlaceHolder,
		searchPlaceHolderMobile,
		quickNavItems,
		searchPageQuickNavItems = [],
	} = useConstants("HomeBannerViewModel");

	const searchPageNavItemOnClickHandler = (serviceName) => () => {
		console.log({ serviceName });
		store
			.getService(serviceName)
			.then((service) => {
				if (!service) return onError({ message: "Service not found" });
				const zipcode = store.zipcode;
				let urlParams = `service=${service.key}`;
				if (zipcode) urlParams += `&zipcode=${zipcode}`;
				if (service.search_to_map) {
					window.location.href = `/search-map?${urlParams}`;
				} else {
					window.location.href = `/search?${urlParams}`;
				}
			})
			.catch(onError);
	};

	return (
		<div id={id}>
			<section className={classes.wrapper}>
				<Box
					width="100%"
					left={0}
					top="50%"
					pr={2}
					pl={2}
					className={classes.heroBox}
				>
					<Container maxWidth={false} className={classes.heroContainer}>
						<h1 className={classes.mainH1}>{title}</h1>

						{subtitle && (
							<Box fontSize={{ xs: 18, sm: 22 }} lineHeight={1.5} mb={6}>
								{subtitle}
							</Box>
						)}

						{searchBar && (
							<div>
								<SearchBar
									id={"search-bar"}
									searchPlaceHolder={
										mobile ? searchPlaceHolderMobile : searchPlaceHolder
									}
									searchOptions={{ active: true }}
								/>

								<Box
									component={"div"}
									textAlign="left"
									display={{ xs: "block", sm: "inline-flex" }}
									alignItems={"center"}
									pt={3}
									className={classes.flexCenter}
								>
									{quickNavItems && quickNavItems.length > 0 && (
										<div>
											{quickNavItems.map((quickNavItem) => (
												<Navigation
													key={shortid.generate()}
													link={quickNavItem.link}
													title={quickNavItem.name}
												/>
											))}
										</div>
									)}
									{searchPageQuickNavItems &&
										searchPageQuickNavItems.length > 0 && (
											<div>
												{searchPageQuickNavItems.map((quickNavItem) => (
													<Navigation
														key={shortid.generate()}
														onClick={searchPageNavItemOnClickHandler(
															quickNavItem.name.toLowerCase()
														)}
														title={quickNavItem.name}
													/>
												))}
											</div>
										)}
								</Box>
							</div>
						)}
					</Container>
				</Box>
			</section>
			<ErrorTemplate />
		</div>
	);
};

HomeBanner.propTypes = {};

export default HomeBanner;
