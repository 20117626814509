import gql from "graphql-tag";

export const statesQueryFragment = gql`
	fragment statesQueryFragment on State {
		name
		abbreviation
	}
`;

export const categoriesQueryFragment = gql`
	fragment categoriesQueryFragment on Category {
		_id
		name
		short_name
		phrase
		url
	}
`;

export const servicesQueryFragment = gql`
	fragment servicesQueryFragment on Service {
		__id
		key
		name
		url
		key
		active
	}
`;

export const serviceQueryFragment = gql`
	fragment serviceQueryFragment on Service {
		_id
		key
		search_to_map
	}
`;
